import Loading from "../Loading";
import { useRef, useState, useEffect } from "react";
import ApiUtil from "../../apiutil/ApiUtil";
import GetCookie from "../GetCookie";
// import "../pages/Home.scss";
import "./Card.scss";

const Card = ({ itemdata, cardStyle, showPic = true, showReply = true }) => {
  const [item, setItem] = useState(itemdata);

  const onitemkeydown = (e, item_id) => {
    if (e.keyCode === 13) {
      onSubmitComment(e, item_id);
    }
  };

  const onSubmitComment = (e, item_id) => {
    e.preventDefault();
    let id = e.target.id;
    let csrftoken = GetCookie("csrftoken");
    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";

    fetch(ApiUtil.URL_COMMENTS + "/add-itemcomment/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
        ...authorization,
      },
      body: JSON.stringify({ id: item_id, content: item.comment }),
    }).then(function (res) {
      if (res.status === 200) {
        // console.log("21121");
        item.comment = "";
        setItem({ ...item });
        // console.log(item);
      }
    });
  };
  const changeImmediate = (new_data) => {
    const newArray = Array.from(new_data).map((item, index) => item);
    setItem(newArray);
  };

  const onLikecount = (e, item_id) => {
    e.preventDefault();
    item.likecount = item.likecount + 1;
    // changeImmediate(item); // 深拷贝使数据实时变化

    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";
    fetch(ApiUtil.URL_API + "/item-likecount/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify({ id: item_id }),
    }).then(function (res) {
      if (res.status === 200) {
        res.json().then(function (response) {
          // items[e.target.id]= items[e.target.id]
          // setItems({...items,items[e.target.id]})
          //   localStorage.setItem("userProfile", response.img);
        });
      }
    });
  };

  const onInputChange = (e) => {
    // var id = e.target.id;
    var value = e.target.value;
    // console.log(id, value, "--");
    // items[id].comment = value;
    setItem({ ...item, comment: value });
  };

  return (
    <div className="card-container task-wrapper">
      <div className="header">
        <div className="date">{item.createdDate}</div>
        <div className="date">
          <figure class="image is-32x32 ">
            <img
              className="avatar"
              // src={"https://source.unsplash.com/random/96x96"}
              src={ApiUtil.URL_IP + item.avatar}
              alt=""
              style={{ height: "32px", width: "32px" }}
            />
          </figure>
        </div>
      </div>
      <div class="card-image">
        <figure class="image is-4by3">
          <img
            src={ApiUtil.URL_IP + item.picture}
            // src="https://source.unsplash.com/random/1280x960"
            alt=""
          />
        </figure>
      </div>
      <div className="content" style={{ height: "6rem", overflow: "auto" }}>
        <span style={{ color: "rgb(10, 61, 98)" }}>{item.tags}</span>
        {item.content}
      </div>
      <div className="foot">
        <div>+{item.likecount}</div>
        <div>
          <i
            class="fa fa-heart"
            aria-hidden="true"
            style={{ color: "#F9C5D7" }}
            onClick={(e) => onLikecount(e, item.id)}
          ></i>
        </div>

        {showPic && (
          <div>
            <i
              class="fa fa-picture-o"
              aria-hidden="true"
              style={{ marginRight: "5px" }}
            ></i>
          </div>
        )}
      </div>
      {showReply && (
        <div className="card-footer">
          <div
            className="columns is-mobile"
            style={{
              backgroundColor: "white",
              margin: "4px 1px 2px 1px",
              width: "100vw",
            }}
          >
            <div
              className="column is-four-fifths"
              style={{ padding: "1px 1px" }}
            >
              <div className="field">
                <div className="control">
                  <input
                    className="input is-medium"
                    type="text"
                    placeholder="发送信息. . ."
                    onChange={onInputChange}
                    value={item.comment}
                    onKeyDown={(e) => onitemkeydown(e, item.id)}
                  />
                </div>
              </div>
            </div>
            <div
              className="column has-text-centered is-one-fifth"
              style={{
                padding: "1px 1px",
                backgroundColor: "white",
              }}
            >
              {/* <button className="button is-small" style={{margin:"0px 0px"}}> */}
              <span className="icon is-small is-left">
                <i
                  className="fa fa-ellipsis-h"
                  aria-hidden="true"
                  onClick={(e) => onSubmitComment(e, item.id)}
                ></i>
              </span>
              {/* </button> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
