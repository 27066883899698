import "./ChryLoading.scss";


const ChryLoading = () => {
  return (
    <div className="ChryLoading">
      <span class="ant-spin-dot ant-spin-dot-spin">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </span>
    </div>
  );
};

export default ChryLoading;
