import "./CommentsModal.scss";
import GetCookie from "../GetCookie";
import { useState, useEffect } from "react";
import ApiUtil from "../../apiutil/ApiUtil";
import "font-awesome/css/font-awesome.min.css";
import MyPagination from "../MyPagination";

const CommentsModal = ({ toast }) => {
  const [commentsList, setCommentsList] = useState([]);
  const [show, setShow] = useState(NaN);
  const [msg, setMsg] = useState("");
  const [pages, setPages] = useState({ totalPages: 0, currtentPage: 1 });
  const [resp, setResp] = useState(undefined);
  const [publish, setPublish] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [btnstate, setBtnstate] = useState(""); // 按钮的状态

  useEffect(() => {
    getComments();
  }, []);

  const getCommentsAndScroll = () => {
    getComments();
    // window.scrollTo(0, 0);
    scrollToAnchor("screens");
  };

  const handleOnShow = (e, index) => {
    e.preventDefault();
    index !== show ? setShow(index) : setShow(NaN);
    setMsg(`@${e.target.target}：`);
    setResp(undefined);

    console.log(e.target.target);
  };

  const handleOnPrevious = (e) => {
    e.preventDefault();
    if (pages.currtentPage !== 1) {
      setPages({ ...pages, currtentPage: pages.currtentPage - 1 });
    }

    getCommentsAndScroll();
  };

  const handleOnClickPage = (e) => {
    e.preventDefault();
    setPages({ ...pages, currtentPage: parseInt(e.target.ariaLabel) });
  };

  const handleOnNext = (e) => {
    e.preventDefault();
    if (pages.currtentPage !== pages.totalPages) {
      setPages({ ...pages, currtentPage: pages.currtentPage + 1 });
    }
    getCommentsAndScroll();
  };
  const getComments = () => {
    let csrftoken = GetCookie("csrftoken");
    let url =
      ApiUtil.URL_IP +
      `/comments/itemscomments/?page=${pages.currtentPage}`;
    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
        ...authorization,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          console.log(data,'data')
          setCommentsList(data.dataexpand);
          setPages({ ...pages, totalPages: data.totalPages });
        });
      } else {
        setMsg(<div>抢占第一把交椅</div>);
      }
    });
  };

  const handleChange = (e) => {
    var value = e.target.value;
    var name = e.target.name;
    if (name === "publish") {
      setPublish(value);
    } else {
      setResp(value);
    }
  };

  const handleAddComments = (e) => {
    e.preventDefault();
    var text = resp;
    if (e.target.name === "publish") {
      setBtnstate("is-loading");
      text = publish;
    }
    if (text === "" || text === undefined) {
      setBtnstate(undefined);
      toast("发送的内容不能为空！");
      return;
    }
    let csrftoken = GetCookie("csrftoken");
    let url = ApiUtil.URL_IP + `/comments/itemcomment-create/`;
    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
        ...authorization,
      },
      body: JSON.stringify({
        content: text,
        // id: id,
        commentId: e.target.id,
        image: image,
      }),
    }).then((res) => {
      if (res.status === 200) {
        setBtnstate(undefined);
        e.target.textContent = "🔥发送";
        toast("👍 已发送！");
        setPublish("");
        setResp("");
        // setImage("");
        getComments();
      } else {
        setBtnstate(undefined);
        e.target.textContent = "发送失败";
        toast("😂 发送失败！");
        // setPublish(""); // 清空评论
        // setResp("");
        // setImage("");
        getComments();
      }
    });
  };

  //   const changeImmediate=(new_data)=>{
  //     const newArray = Array.from(new_data).map(
  //       (item, index) => item
  //     )
  //      setCommentsList(newArray)
  //   }

  //   const scrollToAnchor = (anchorName) => {
  //     if (anchorName) {
  //         let anchorElement = document.getElementById(anchorName);
  //         if(anchorElement) { anchorElement.scrollIntoView(); }
  //     }
  //   }

  const scrollToAnchor = (anchorName) => {
    // 加入动作
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    <div className="comments-container">
      {false && <div className="comment-reply-box">
        <div className="comment-item">
          <img
            className="comment-avatar"
            src={ApiUtil.URL_IP + localStorage.getItem("avatar")}
            alt=""
          />
          <div className="comment-box">
            <textarea
              className="textarea-input"
              placeholder="说点什么"
              name="publish"
              value={publish}
              onChange={handleChange}
            ></textarea>
            <div className="buttons">
              <button
                className={`button is-quan is-small1 ${btnstate}`}
                style={{ height: "30px" }}
                onClick={handleAddComments}
                name="publish"
                id={0}
              >
                发 送
              </button>
            </div>
          </div>
        </div>
      </div>}
      <div className="comments-header" id="screens">
        {/* <a id="screens"></a> */}
        <span className="comments-link">{commentsList.length}条留言</span>
      </div>
      <div className="comments">
        {commentsList.length === 0 && (
          <div className="comment-item">
            <img
              className="comment-avatar"
              src={ApiUtil.URL_IP + "/media/images/None/no-img.jpg"}
              alt=""
            />
            <div className="comment-content">
              <div className="comment-header">
                <div className="comment-name-wrapper">
                  <div className="comment-name">TIMEUS</div>
                  <div>最真实好玩的平行世界</div>
                </div>
                {/* <div className="comment-tool">
                <i class="fa fa-reply" aria-hidden="true" />
              </div> */}
              </div>
              <div className="comment-body">
                <p>你好，TIMEUS</p>
              </div>
            </div>
          </div>
        )}
        {commentsList.map((comment, index) => {
          return (
            <>
              <div className="comment-item" key={index}>
                <img
                  className="comment-avatar"
                  src={ApiUtil.URL_IP + comment.senderAvatar}
                  alt=""
                />
                <div className="comment-content">
                  <div className="comment-header">
                    <div className="comment-name-wrapper">
                      <div className="comment-name">{comment.sendername}</div>
                      <div className="comment-aboutme is-hidden-mobile">
                        <span>{comment.tag}</span>
                      </div>
                      <div className="comment-timedelta">
                        {comment.timedelta}
                      </div>
                    </div>
                    <div className="comment-tool">
                      <i
                        target={comment.nickname}
                        onClick={(e) => handleOnShow(e, index)}
                        className="fa fa-reply"
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  {(comment.parentKey!==0 && comment.parentKey!==null) && (
                    <div className="comment-body-up">
                      <p>{`@${comment.parent.nickname}`}</p>
                      <p style={{ color: "#222" }}>{comment.parent.content}</p>
                    </div>
                  )}
                  <div className="comment-body">
                    <p>{comment.content}</p>
                  </div>
                  <div className="comment-footer">
         
                        <img 
                        className="obj-pic"
                        alt=""
                        src={ApiUtil.URL_IP+`${comment.obj.picture?comment.obj.picture:comment.obj.avatar}`}/>
                      <div className="obj-content">
                    <div className="obj-nickname">
                      @{comment.obj.nickname}
                    </div>
                    <div className="obj-text">
                       {comment.obj.content}
                    </div>
                    <div>

                    </div>
                    </div>
                    </div>
                </div>
               
              </div>
              <div
                className="comment-reply-box"
                key={index + comment}
                style={{ display: show === index ? "" : "none" }}
              >
                <div className="comment-item">
                  <img
                    className="comment-avatar"
                    src={ApiUtil.URL_IP + `${localStorage.getItem("avatar")}`}
                    alt=""
                  />
                  <div className="comment-box">
                    <textarea
                      className="textarea-input"
                      name="response"
                      placeholder={`@${comment.sendername}`}
                      value={resp}
                      onChange={handleChange}
                    ></textarea>
                    <div className="buttons">
                      <button
                        className="button is-quan is-small"
                        name="response"
                        id={comment.id}
                        onClick={handleAddComments}
                      >
                        发 送
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
        <MyPagination
          totalPages={pages.totalPages}
          currtentPage={pages.currtentPage}
          handleOnClickPage={handleOnClickPage}
          handleOnPrevious={handleOnPrevious}
          handleOnNext={handleOnNext}
        ></MyPagination>
      </div>
    </div>
  );
};

export default CommentsModal;
