import React, { Component } from 'react';
// import imgURL1 from "../images/chat.svg";
import '../icon/iconfont.css';

class MyModal extends Component {
    constructor(props) {
        super(props);
    }

    handleOnClick = () => {
        this.props.handleOnClick()
    }

    render() {
        return (
            <div className="modal" style={{ display: this.props.isShow === false ? "none" : "flex" }}>

                {/* <div class="modal-background" onClick={this.handleOnClick}></div> */}
                <button class="delete" aria-label="close" onClick={this.handleOnClick}></button>
                <div class="modal-content">

                    <div className="box">
                        {/* <header>
                            <button class="modal-close is-large" aria-label="close"
                                onClick={this.handleOnClick} style={{ color: "red", backgroundColor: "yellow" }}></button>

                        </header> */}
                        <article className="media">
                            <div className="media-left">
                                <figure className="image is-64*64">
                                    <img src=
                                        "https://media.geeksforgeeks.org/wp-content/uploads/20200611151025/gfg202.png" />
                                </figure>
                            </div>
                            <div className="media-content">
                                <div className="content">
                                    <p>
                                        <strong>GeekforGeeks</strong> <small>@geekforgeeks</small> <small>36m</small>
                                        <br />
                                            Hey, There!
                      <br />
                                                'GeeksforGeeks' is a computer science
                                                portal.it was created with a goal in
                                                mind to provide well written, well
                                                thought and well explained solutions
                                                for selected questions. The core team
                                                of five super geeks constituting of
                                                technology lovers and computer science
                                                enthusiasts have been constantly working
                                                in this direction .

                                       </p>

                                </div>

                                <nav class="level is-mobile">
                                    <div class="level-left">
                                        <a class="level-item">
                                            <span class="icon has-text-danger	 ">
                                                <i className="iconfont icon-6"></i>
                                            </span>
                                        </a>
                                        <a class="level-item">
                                            <span class="icon has-text-danger	 ">
                                                <i className="iconfont icon-19"></i>
                                            </span>
                                        </a>
                                        <a class="level-item">
                                            <span class="icon has-text-danger	">
                                                <i className="iconfont icon-14"></i>
                                            </span>
                                        </a>
                                    </div>
                                </nav>

                            </div>
                            <div class="media-right">
    <button class="delete"  aria-label="close" onClick={this.handleOnClick}></button>
  </div>
                        </article>
                    </div>



                    {/* <p class="image is-4by3"> */}
                    {/* {this.props.pickPosition.longitude, this.props.pickPosition.latitude} */}
                    {/* <img src="https://bulma.io/images/placeholders/1280x960.png" alt="" /> */}
                    {/* </p> */}
                </div>
                {/* <button class="modal-close is-large" aria-label="close"
                            onClick={this.handleOnClick}></button> */}
            </div>
        )

    }
}



// const MyModal = () => {
//     const [disp, setDisp] = useState("none")
//     console.log(disp)
//     var disp = "none";
//     return (
//         <div class="modal" id="myModal" style={{ display: disp === "none" ? "none" : "flex" }}>

//             <div class="modal-background" onClick={() => { disp = "none" }}></div>
//             <div class="modal-content">
//                 <p class="image is-4by3">
//                     <img src="https://bulma.io/images/placeholders/1280x960.png" alt="" />
//                 </p>
//             </div>
//             <button class="modal-close is-large" aria-label="close"
//                 onClick={() => { disp = "none" }}>在这里</button>
//         </div>
//     )
// }

export default MyModal;
