import React from "react";
// import GetPosition from "../components/GetPosition";
import './NoneUs.scss'

function NoneUs() {
  // GetPosition();
  return (
    <div>
      <div
        class="none-container has-text-centered"
      >
        <div class="columns is-mobile is-centered">
          <div class="column is-5">
            <div class="notification is-warning">
              <h1 class="title">提示</h1>

              <p class="is-family-monospace">马上开通，敬请期待。</p>
              <button class="delete"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoneUs;
