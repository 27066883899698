import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../components/user-auth";
import GetCookie from "../../components/GetCookie";
import ApiUtil from "../../apiutil/ApiUtil";
import "./Login.scss";
import "font-awesome/css/font-awesome.min.css";
import isValidEmail from "../../components/validation";
import GetPosition from "../../components/GetPosition";
import { Link } from "react-router-dom";

const Login = () => {
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  const [isPasswordHidden, setIsPasswordHidden] = useState(true);
  const [emailError, setEmailError] = useState(undefined);
  const [passwordError, setPasswordError] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  const handleChange = (e) => {
    var id = e.target.id;
    var value = e.target.value;
    setUserInfo({
      ...userInfo,
      [id]: value,
    });
    console.log(userInfo);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.target.setAttribute("class", "button is-timeus login-btns is-loading");

    let { from } = location.state || { from: { pathname: "/" } };
    var csrftoken = GetCookie("csrftoken");
    var url = ApiUtil.URL_API + "/token/";
    // var url = ApiUtil.URL_API + '/user-login/'
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({ ...userInfo, username: userInfo.email }),
    })
      .then((responseT) => responseT.json())
      .then((response) => {
        e.target.setAttribute("class", "button is-timeus login-btns");
        if (response.access) {
          localStorage.setItem("access", response.access);
          localStorage.setItem("refresh", response.refresh);
          localStorage.setItem("avatar", response.avatar);
          auth.setAccess(response.access);
          // auth.setRefresh(response.refresh);
          auth.signin(() => {
            history.replace(from);
          });
          GetPosition();
        } else {
          setErrorMessage("邮箱或者密码错误，也可以尝试用户名/手机登入");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <form className="login section">
          <h4 className="title is-4">登录👋</h4>
          <div className="field">
            <label htmlFor="email" className="label">
              邮箱
              <div className="control has-icons-left">
                <input
                  className={`input ${emailError ? "is-danger" : ""}`}
                  id="email"
                  type="text"
                  value={userInfo.email}
                  onChange={(e) => {
                    handleChange(e);
                    setEmailError(undefined);
                  }}
                  // style={{
                  //   outlineStyle: "none",
                  //   outline: "none",
                  //   borderRadius: "6px",
                  //   backgroundColor: "#fff",
                  //   boxShadow: "0 0 0px 1000px white inset",
                  //   color: "#0d0d0d",
                  //   fontSize: "18px",
                  // }}
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
                </span>
              </div>
              {emailError && <p className="help is-danger">{emailError}</p>}
            </label>
          </div>

          <div className="field">
            <label htmlFor="password" className="label">
              密码
              <div className="control has-icons-left has-icons-right">
                <input
                  className={`input ${passwordError ? "is-danger" : ""}`}
                  id="password"
                  type={`${isPasswordHidden ? "password" : "text"}`}
                  value={userInfo.password}
                  onChange={(e) => {
                    handleChange(e);
                    setPasswordError(undefined);
                  }}
                  // style={{
                  //   outlineStyle: "none",
                  //   outline: "none",
                  //   borderRadius: "6px",
                  //   backgroundColor: "#fff",
                  //   boxShadow: "0 0 0px 1000px white inset",
                  //   color: "#0d0d0d",
                  //   fontSize: "18px",
                  // }}
                />
                <span className="icon is-small is-left">
                  <i className="fa fa-key" aria-hidden="true"></i>
                </span>
                {userInfo.password.length > 0 && (
                  <span
                    className="icon is-small is-right"
                    tabIndex={0}
                    role="button"
                    onClick={() => {
                      setIsPasswordHidden(!isPasswordHidden);
                    }}
                    onKeyDown={({ key }) =>
                      key === "Enter" && setIsPasswordHidden(!isPasswordHidden)
                    }
                  >
                    {isPasswordHidden ? (
                      <i className="fa fa-eye" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                    )}
                  </span>
                )}
              </div>
            </label>
            {passwordError && <p className="help is-danger">{passwordError}</p>}
          </div>

          {errorMessage && (
            <div className="notification is-danger is-light">
              {errorMessage}
            </div>
          )}

          <div className="field is-grouped login-btns">
            <button
              type="button"
              className="button is-timeus login-btns"
              onClick={(e) => {
                e.preventDefault();
                let error = false;
                if (userInfo.email.length < 1) {
                  setPasswordError("邮箱不能为空(试试用用户名或者手机号登录)");
                  error = true;
                } else {
                  setEmailError(undefined);
                }
                if (userInfo.password.length < 1) {
                  setPasswordError("密码不能为空");
                  error = true;
                } else {
                  setPasswordError(undefined);
                }
                if (error) return;
                handleSubmit(e);
              }}
            >
              登 录
            </button>
            <div className="control to-reset">
              <Link to="/reset" className="is-text">
                忘记密码？
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
