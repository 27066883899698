import React from "react";
import { Link } from "react-router-dom";
import "./FlagUs.scss";
import { useState, useEffect } from "react";

const wordList = [
    "如果你结婚了记得给我寄张请柬，当时我没及格，我想看看标准答案。",
    "这辈子我不打扰你了，如果下辈子我们还能遇见的话，请你一定要好好爱我，她很好看。",
    "越长大越应该知道，除了公式定理，这个世界上没有标准答案，只有参考答案。",
    "夜色正好，思念正浓，而你在哪？",
    "珍惜下一秒的缘分，朋友是一辈子的，但是陌生人不会，下一秒的离开就成为永恒。",
    "今天就想了你三次，一次24小时，一次1440分钟，一次86400秒。",
    "我想有你在的未来。",
    "遇见你，爱你成了我全部。",
    "如果转角没有你，我就在转角一直等你。",
    "喜欢一个人就去表白，万一成备胎了呢？",
    "小时候词不达意，长大后言不由衷。",
    "人是会变的，简单五个字讲完了所有故事。",
    "凌晨4点，你再不来，我要下雪了。",
    "有两个你，一个在我眼里，一个在我心里。",
    "我总是会在不经意的时候看看你，你在就好。",
    "这么多年，你的身边是否还留有我的东西？",
    "珍惜每一个陌生的人，朋友一辈子都会在身边，陌生人不会，下一秒可能永远不会再见。",
    "时间不会让我忘了你，只会让我习惯没有你。",
    "人和人刚认识的时候最好。",
    "可爱是装的，可爱你不是。",
    "我走了，你一个人睡觉记得锁门。",
    "这个年纪错过就是错过了。",
    "她们说我今天很好看，要是今天能遇见你该多好。",
    "我的每支笔都记得你的名字",
    "我和你不算干净朋友，也不算正大光明的情侣，不见面就是陌生人，没有关系，我自己选的我认栽。",
    "我知道虚度年华不对，但是这样，真的好过瘾啊"
  ];

const getRandom = (v) => {
    const random = Math.floor(Math.random() * v)
    return random
  }
  
const FlagUs = ()=>{
    const [flag, setFlag] = useState(wordList)
     return(
        <div className="Flagus container">
            <section className="flag-box">
                <div className="card-content">
                    {flag.map((fg, idx) => {
                        return(
                          <div key={idx}>
                              {/* <a href="#" */}
                              <div
                              style = 
                              {{
                                 color:`rgb(${getRandom(255)},${getRandom(200)},${getRandom(255)})`,
                            fontSize:`${getRandom(24)}px` //,fontWeight:"lighter"
                            }}>{fg}
                            {/* </a> */}
                            </div>
                          </div>)
                    })}
                </div>
            </section>
        </div>
    )
}


export default FlagUs
