import { useRef, useState, useEffect } from "react";
import GetCookie from "../../components/GetCookie";
import ApiUtil from "../../apiutil/ApiUtil";
import { Link, Redirect } from "react-router-dom";
import "./Register.scss";
// import "react-bulma-components/dist/react-bulma-components.min.css";

// import "bulma-extensions/dist/css/bulma-extensions.min.css";
// import BulmaTagsInput from "@creativebulma/bulma-tagsinput";
// import '@creativebulma/bulma-tagsinput/dist/css/bulma-tagsinput.min.css'
import isValidEmail from "../../components/validation";
// import { DraggableArea } from "react-draggable-tags";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "font-awesome/css/font-awesome.min.css";
import { useHistory, useLocation } from "react-router-dom";

const tags = [
  { id: 1, content: "跑步" },
  { id: 2, content: "旅行" },
  { id: 3, content: "阅读" },
  { id: 4, content: "孤独" },
  { id: 5, content: "内向" },
  { id: 6, content: "自律" },
  { id: 7, content: "茶树菇" },
  { id: 8, content: "单质" },
  { id: 9, content: "淡淡的忧伤" },
  { id: 10, content: "偏执狂" },
  { id: 11, content: "理想主义" },
  { id: 12, content: "中央空调" },
  { id: 13, content: "大叔" },
  { id: 14, content: "80后" },
  { id: 15, content: "70后" },
  { id: 16, content: "90后" },
  { id: 17, content: "00后" },
  { id: 18, content: "10后" },
  { id: 19, content: "过期的小朋友" },
  { id: 20, content: "偏执狂" },
  { id: 21, content: "理性" },
  { id: 22, content: "正直" },
  { id: 23, content: "游戏" },
  { id: 24, content: "is-best" },
  { id: 25, content: "艺术" },
  { id: 26, content: "老阿姨" },
  { id: 27, content: "超爱jy" },
  { id: 28, content: "当地小有名气的小可爱" },
  { id: 29, content: "在线招十个男朋友，先到的当队长" },
  { id: 30, content: "代表月亮消灭海王" },
  { id: 31, content: "小资" },
  { id: 32, content: "K歌" },
  { id: 33, content: "坦荡" },
  { id: 34, content: "爱动物" },
  { id: 35, content: "真诚" },
  { id: 36, content: "善良" },
  { id: 37, content: "美丽" },
  { id: 38, content: "梦想" },
  { id: 39, content: "眼缘" },
  { id: 40, content: "小众" },
];

const Register = () => {
  const randomTags = Math.floor(Math.random() * tags.length);
  const inputRef = useRef(null);
  const [userInfo, setUserInfo] = useState({
    email: "",
    telephone: "",
    password: "",
    nickname: "",
    username: "",
    wechat: "",
    gender: "",
    tag: tags[Math.floor(Math.random() * tags.length)].content,
    // tags: [
    //   tags[Math.floor(Math.random() * tags.length)],
    //   tags[Math.floor(Math.random() * tags.length)],
    //   tags[Math.floor(Math.random() * tags.length)],
    // ],
  });
  const [usernameIsAvailable, setUsernameIsAvailable] = useState(false); // 标记用户名是否可用
  const [checkIfUsernameExists, setCheckIfUsernameExists] = useState(false); // 检查用户名是否存在
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [page, setPage] = useState(1);
  const [seconds, setSeconds] = useState(0);
  let history = useHistory();

  const checkUsernameMsg = () => {
    if (checkIfUsernameExists) {
      return <p className="help">检查用户名是否可用...</p>;
    }
    if (userInfo.username && userInfo.username.length < 4) {
      return <p className="help">用户名太短！</p>;
    }
    if (
      userInfo.username &&
      /^[a-zA-Z0-9_]+$/g.test(userInfo.username) === false
    ) {
      return <p className="help">只能是数字、字母或者下划线组合！</p>;
    }
    if (usernameIsAvailable === true) {
      return <p className="help">用户名可用！</p>;
    }
    if (userInfo.username && usernameIsAvailable === false) {
      return <p className="help">用户名不可用！</p>;
    }
    if (userInfo.username.length > 15) {
      return <p className="help">用户名太长！</p>;
    }

    return null;
  };

  const handleChange = (e) => {
    var id = e.target.id;
    var value = e.target.value;
    const isValidUsername = id === "username" && value.length > 4;
    var new_data = {
      ...userInfo,
      [id]: value,
    };
    setUserInfo(new_data);

    if (isValidUsername) {
      setCheckIfUsernameExists(true);
      checkUsername(e, value);
    }
  };

  const handleClickDelete = (tag) => {
    const tags = userInfo.tags.filter((t) => tag.id !== t.id);
    setUserInfo({ ...userInfo, tags: tags });
  };

  const onkeydown = (e) => {
    if (e.keyCode === 13) {
      handleClickAdd();
    }
  };

  const handleCountDown = (sec = 60) => {
    let secTemp = sec;
    const countDown = () => {
      if (secTemp > 0) {
        secTemp--;
        setSeconds(secTemp);
      }
      if (secTemp === 0) {
        return;
      }
      setTimeout(countDown, 1000);
    };
    setTimeout(countDown, 1000);
  };

  const handleClickAdd = () => {
    console.log("here");
    console.log("input值", inputRef.current.value);
    if (inputRef.current.value.length < 1) return;
    const tags = userInfo.tags.slice();
    tags.push({ id: Math.random(), content: inputRef.current.value });
    setUserInfo({ ...userInfo, tags: tags });
    inputRef.current.value = "";
  };

  const checkUsername = (e, value) => {
    e.preventDefault();
    var csrftoken = GetCookie("csrftoken");
    var url = ApiUtil.URL_API + "/username-check/";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({ username: value }),
    })
      .then((response) => {
        if (response.status === 200)
          response.json().then((data) => {
            if (data.exist === true) {
              setUsernameIsAvailable(!data.exist);
              setCheckIfUsernameExists(false);
            } else {
              setUsernameIsAvailable(!data.exist);
              setCheckIfUsernameExists(false);
            }
          });
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
  };

  const pageInfo = () => {
    return (
      <div className="pageinfo-container">
        <div className="field">
          <div className="control2">
            <label htmlFor="nickname" className="label">
              昵称
              <input
                className="input"
                id="nickname"
                type="text"
                name="nickname"
                maxLength="20"
                value={userInfo.nickname}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>

        <div className="field">
          <div className="control2">
            <label htmlFor="username" className="label">
              用户名
              <input
                className="input "
                id="username"
                type="text"
                name="username"
                maxLength="20"
                onChange={handleChange}
                value={userInfo.username}
                // style={{ outline: "none", boxShadow: "none" }}
              />
            </label>
          </div>
          {checkUsernameMsg()}
        </div>

        <div className="field">
          <div className="control2">
            <label
              htmlFor="username"
              className=" label"
              style={{ marginBottom: "0px" }}
            >
              性别
              {/* <input
                className="input "
                id="username"
                type="text"
                name="username"
                maxLength="20"
                onChange={handleChange}
                value={userInfo.username}
              /> */}
            </label>
            <div className="select is-fullwidth">
              <select
                id="gender"
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="F">👧女</option>
                <option value="M">👦男</option>
              </select>
            </div>
          </div>
          {checkUsernameMsg()}
        </div>

        <div className="field">
          <div className="control2">
            <label htmlFor="tag" className="label">
              标签
              <input
                className="input"
                id="tag"
                type="text"
                name="tag"
                maxLength="20"
                onChange={handleChange}
                value={userInfo.tag}
              />
            </label>
          </div>
          {/* {checkUsernameMsg()} */}
        </div>

        {/* <div
          className="field"
          style={{ display: `${page === 2 ? "none" : "black"}` }}
        >
          <div className="control2">
            <label htmlFor="tags" className="label">
              标签
            </label> */}

        {/* <div className="main">
              <DraggableArea
                tags={userInfo.tags}
                render={({ tag, index }) => (
                  <div className="tag" undraggable="true">
                    <span>{tag.content}</span>
                    <span
                      className="icon is-small is-right"
                      onClick={() => handleClickDelete(tag)}
                    >
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={() => handleClickDelete(tag)}
                      ></i>
                    </span>
                  </div>
                )}
                onChange={(tags) => setUserInfo({ ...userInfo, tags: tags })}
              />
            </div>
            <div className="control has-icons-right" onClick={handleClickAdd}>
              <input
                className="input"
                id="tag"
                type="text"
                name="tag"
                ref={inputRef}
                onKeyDown={(e) => onkeydown(e)}
              />
              <label
                className="icon is-small is-right"
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-plus" aria-hidden="true" />
              </label>
            </div>
          </div>
        </div> */}

        <div className="field is-grouped register-btns">
          <div className="noncontrol">
            <button
              type="button"
              className="button is-timeus is-made"
              onClick={() => {
                if (userInfo.nickname.length <= 2) {
                  return setErrorMessage("昵称太短");
                }
                if (userInfo.username.length <= 4) {
                  return setErrorMessage("用户名太短");
                }
                if (usernameIsAvailable === false) {
                  return setErrorMessage("用户名不可用");
                }
                if (userInfo.tag.length < 0) {
                  return setErrorMessage("标签没有输入");
                }
                return setPage(() => 2);
              }}
            >
              继 续
            </button>
          </div>

          <div className="control to-login">
            <Link to="/login" className="is-text">
              已有账号？
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const pageAccount = () => {
    return (
      <>
        <div className="field">
          <div className="control2">
            <label htmlFor="wechat" className="label">
              微信
              <input
                className="input"
                id="wechat"
                type="text"
                name="wechat"
                value={userInfo.wechat}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className="field">
          <div className="control2">
            <label htmlFor="email" className="label">
              邮箱
              <input
                className="input"
                id="email"
                type="text"
                name="email"
                value={userInfo.email}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>

        <div className="field">
          <div className="control2">
            <label htmlFor="telephone" className="label">
              手机
              <input
                className="input"
                id="telephone"
                type="text"
                name="telephone"
                value={userInfo.telephone}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>

        <div className="field">
          <div className="control2">
            <label htmlFor="password" className="label">
              密码
              <input
                className="input"
                id="password"
                type="password"
                name="password"
                value={userInfo.password}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>

        {/* <div className="field">
        <div className="control">
          <label htmlFor="checkbox" className="checkbox">
            <input type="checkbox" id="checkbox" />
            <span className="checkbox-label">
              I agree to the
              <a href="https://google.com"> terms and conditions</a>
            </span>
          </label>
        </div>
      </div> */}

        <div className="noncontrol register-btns">
          <button
            type="button"
            className="button is-timeus"
            onClick={(event) => {
              event.preventDefault();
              try {
                if (!userInfo.wechat) {
                  return setErrorMessage("微信不能为空");
                }
                if (!isValidEmail(userInfo.email)) {
                  return setErrorMessage("邮箱格式有误");
                }
                const reg = /^1([0-9]*)?$/; // 以数字1开头，任意数字结尾，且中间出现零个或多个数字
                if (
                  !reg.test(userInfo.telephone) ||
                  userInfo.telephone.length !== 11 ||
                  userInfo.telephone === ""
                ) {
                  return setErrorMessage("手机格式有误");
                }
                if (userInfo.password.length < 6) {
                  return setErrorMessage("密码不能小于6位");
                }
                if (
                  isValidEmail(userInfo.email) &&
                  reg.test(userInfo.telephone) &&
                  userInfo.telephone.length === 11
                ) {
                  // 发送注册到后端
                  toast("😆注册成功后自动跳转到登录。");

                  let csrftoken = GetCookie("csrftoken");
                  let url = ApiUtil.URL_API + "/register/";
                  fetch(url, {
                    method: "POST",
                    headers: {
                      "Content-type": "application/json",
                      "X-CSRFToken": csrftoken,
                    },
                    body: JSON.stringify({ ...userInfo }),
                  }).then((res) => {
                    if (res.status === 400) {
                      res.json().then(function (response) {
                        setErrorMessage(response.detail);
                      });
                    } else {
                      setErrorMessage("");
                      event.target.textContent = "注册中(5)";
                      handleCountDown(5);
                      event.target.disabled = true;
                      setTimeout(() => {
                        history.push("/login");
                      }, 5000);
                    }
                  });
                }
              } catch (e) {
                setErrorMessage(e.message);
              }
            }}
          >
            {`${seconds > 0 ? "注册中(" + seconds + ")" : "注 册"}`}
            {/* >
            注册 */}
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="register-container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="register-form">
        <form className="section" autoComplete="off">
          <h4 className="title is-4">注册🤟</h4>
          <p className="hp">欢迎来到timeus!</p>
          {errorMessage && (
            <div className="notification is-danger is-light">
              {errorMessage}
            </div>
          )}
          {page === 1 ? pageInfo() : pageAccount()}
        </form>
      </div>
    </div>
  );
};

export default Register;
