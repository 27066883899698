import AMapJS from "amap-js";
import ApiUtil from "../apiutil/ApiUtil";
import GetCookie from "../components/GetCookie";

function GetPosition() {
  // 创建AMapLoader
  const loader = new AMapJS.AMapLoader({
    key: "d985564ffc04b7ecdce9216965940cc9",
    version: "1.4.15",
    plugins: ["AMap.Geolocation", "AMap.CitySearch"],
  });

  loader
    .load()
    .then(() => {
      // 请求成功
      console.log("---------------------");
      console.log(loader.AMap); // => window.AMap === loader.AMap
      console.log("aaaaaaaaaaa");
      const Geolocation = new loader.AMap.Geolocation();
      Geolocation.getCurrentPosition((status, result) => {
        console.log(result.info);
        console.log("0000000");

        if (status === "complete" && result.info === "SUCCESS") {
          const location = result.position;
          // 地图显示当前城市
          console.log("dangqiangchegnshi");
          console.log(location.lng);
          console.log(location.lat);
          console.log("9090");
          console.log(result);
          console.log(result.addressComponent);
          let province = "上海市"
          if (result.addressComponent){
            console.log(result.addressComponent.province)
            province = result.addressComponent.province
          }else{
            console.log('meiyou ')
          }
//          console.log(result.addressComponent.province);
//          localStorage.setItem("city", result.addressComponent.province); // 保存到本地
          localStorage.setItem("longitude", location.lng);
          localStorage.setItem("latitude", location.lat);
          // fetch到后台更新地址
          if (localStorage.getItem("access")) {
            var url = ApiUtil.URL_API + "/userposition/";
            var csrftoken = GetCookie("csrftoken");

            fetch(url, {
              method: "POST",
              headers: {
                "Content-type": "application/json",
                "X-CSRFToken": csrftoken,
                Authorization: "JWT " + localStorage.getItem("access"),
              },
              body: JSON.stringify({
                city: province,
                longitude: location.lng,
                latitude: location.lat,
              }),
            });
          }

          // .then((responseT) => responseT.json())
          // .then((resp) => {
          //   if (resp.status === 200) {
          //     localStorage.setItem("city", result.addressComponent.province); // 保存到本地
          //     localStorage.setItem("longitude", location.lng);
          //     localStorage.setItem("latitude", location.lat);
          //   }
          // });
        }
      });
      // citySearch.getLocalCity((status, result) => {
      //     if (status === 'complete' && result.info === 'OK') {
      //         // 查询成功，result即为当前所在城市信息
      //         console.log('当前所在城市：', result)
      //         if (result && result.city && result.bounds) {
      //             // 当前城市名称
      //             // const cityinfo = result.city;
      //             // 当前城市位置信息
      //             const citybounds = result.bounds;
      //             // 地图显示当前城市
      //             console.log('dangqiangchegnshi')
      //         }
      // }
      // })
    })
    .catch((e) => {
      // 请求失败
    });
}

export default GetPosition;

// import AMapJS from "amap-js";

// function GetPosition() {
//   // 创建AMapLoader
//   const loader = new AMapJS.AMapLoader({
//     key: "d985564ffc04b7ecdce9216965940cc9",
//     version: "1.4.15",
//     plugins: ["AMap.CitySearch"],
//   });

//   loader
//     .load()
//     .then(() => {
//       // 请求成功
//       console.log("---------------------");
//       console.log(loader.AMap); // => window.AMap === loader.AMap
//       console.log("aaaaaaaaaaa");
//       const citySearch = new loader.AMap.CitySearch();
//       citySearch.getLocalCity((status, result) => {
//         if (status === "complete" && result.info === "OK") {
//           // 查询成功，result即为当前所在城市信息
//           console.log("当前所在城市：", result);
//           if (result && result.city && result.bounds) {
//             // 当前城市名称
//             // const cityinfo = result.city;
//             // 当前城市位置信息
//             const citybounds = result.bounds;
//             // 地图显示当前城市
//             console.log("dangqiangchegnshi");
//           }
//         }
//       });
//     })
//     .catch((e) => {
//       // 请求失败
//     });
// }

// export default GetPosition;
