// import "./bulma_extensions.css";

 
const MyPagination = ({ totalPages, currtentPage, handleOnPrevious, handleOnNext, handleOnClickPage}) => {
    
  var arrayPages = [] ;
  if (totalPages > 0) {
    var tempPages = 
      Array.from({ length: 5 }, (v, i) => currtentPage - (4-i)).concat(
        Array.from({ length: 5 }, (v, i) => i + currtentPage)
    );
    
   
    console.log("tempPages:",tempPages)
    var newArr = [...new Set(tempPages)]
    console.log("newArr:",newArr)
    // var arrayPages = new Array();
    newArr.forEach((p, idx) => {
      if ((p > 0) & (p <= totalPages) & (arrayPages.length < 7)) {
        // 总的展示的页数是7页
        arrayPages.push(p);
      }
    });
    console.log(arrayPages, "====arrayPages===");
  }
   
  return (
    <div>
      <nav
        class="pagination is-rounded"
        role="navigation"
        aria-label="pagination"
      >
        <a class="pagination-previous" onClick={handleOnPrevious} disabled={currtentPage === 1 ? true : false}>上一页</a>
        <a class="pagination-next" onClick={handleOnNext} disabled={currtentPage === totalPages ? true : false}>下一页</a>
          <ul class="pagination-list">

        {arrayPages.map((p, idx) => {
          return(
          <li key={idx}>
            <a
              className={`pagination-link ${
                currtentPage === p ? "is-current" : " "
              }`}
              // aria-label={`Goto page ${p}`} 
              aria-label={p}
              onClick={handleOnClickPage}
            >
             {p}
            </a>
          </li>)
        })}
        </ul>
      </nav>
    </div>
  );
};

export default MyPagination;
