import ApiUtil from "../../apiutil/ApiUtil";
import React from "react";
import "./BeautyUs.scss";
import BeautyCard from "../../components/beautycard/BeautyCard";
import { useRef, useState, useEffect } from "react";

const BeautyUs = () => {
  const [items, setItems] = useState(undefined);

  useEffect(() => {
    fetchItems();
    return () => {};
  }, []);

  const fetchItems = () => {
    console.log("Fetching...");
    fetch(ApiUtil.URL_API + "/beauty-list/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "zheloi");
        setItems(data);
      });
  };

  return (
    <div className="container">
      <div className="beauty-container">
        <div className="columns">
          <div className="column  col-left is-hidden-mobile">3232</div>
          <div className="column is-6 col-mid">
            <div className="home-post-top">
              <button className="close-button">
                <span aria-hidden="true">×</span>
              </button>
              <h3 className="text">所谓伊人，在水一方</h3>
              <p>
                这是一个选美的社区，选出最美的女孩子可以获得平台奖金池的80% !
              </p>
              <button type="button" className="button is-timeus charge-button ">
                充值
              </button>
            </div>
            <div className="select-option">
              <div className="hot-btn">
                <a href="https://www.ezlauncher.com" className=" ">
                  男
                </a>
                <a href="https://www.ezlauncher.com" className=" ">
                  女
                </a>

                <a
                  href="https://www.ezlauncher.com"
                  className="active button1 "
                >
                  All
                </a>

                <a href="https://www.ezlauncher.com" className=" ">
                  Newest
                </a>
                <a className="button1 sb-btn is-timeus" role="button">
                  🚀上传
                </a>
              </div>
            </div>
            <div>
              {/* <figure class="image is-4by3">
                <img alt="" style={{width:"100%"}} src="https://bulma.io/images/placeholders/256x256.png"/>
              </figure> */}
              {items &&
                items.map((item, index) => {
                  return <BeautyCard item={item} key={index} />;
                })}
              <BeautyCard />
              <BeautyCard />
              <BeautyCard />

              {/* <img alt="" style={{marginTop:"10px",width:"100%",height:"190px"}} src="https://bulma.io/images/placeholders/256x256.png"/> */}
            </div>
          </div>
          <div className="column  col-right is-hidden-mobile">2323</div>
        </div>
        <footer class="footer">
          <div class="content has-text-centered">
          <p>
            <strong>Made by </strong>
            <a href="https://www.xunsitech.com/">寻斯科技 😇</a>
              <strong>{" | "}</strong>
              <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">沪ICP备2021020260号-2</a>
          </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default BeautyUs;
