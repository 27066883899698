/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "./App.css";
import ApiUtil from "./apiutil/ApiUtil";
import { Link } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Search from "./pages/search/Search";
import Landing from "./pages/landing/Landing";
// import FriendlyLink from "./pages/FriendlyLink";
import "react-bulma-components/dist/react-bulma-components.min.css";
import "bulma/css/bulma.min.css";
import Logout from "./pages/logout/Logout";
import About from "./pages/about/About";
import BeautyUs from "./pages/beautyus/BeautyUs";
import MapUs from "./pages/mapus/MapUs";
import FlagUs from "./pages/flagus/FlagUs";
import NoneUs from "./pages/none/NoneUs";
import { PrivateAuth, PrivateRoute, useAuth } from "./components/user-auth";
import logoURL from "./images/logo.png";
import "./pages/mystyles.scss";
import GetPosition from "./components/GetPosition";
import Usercenter from "./pages/usercenter/Usercenter";
import Upload from "./pages/beautyus/upload/Upload";

function App() {
  const [isActive, setIsActive] = React.useState(false);
  return (
    <div className="container">
      <PrivateAuth>
        <Router>
          <nav
            className="navbar is-transparent has-shadow"
            role="navigation"
            aria-label="main navigation"
            // style={{
            //   position: "fixed",
            //   width: "100%",
            // }}
          >
            <div className="navbar-brand">
              <a className="navbar-item" href="https://timeus.top">
                <img
                  src={logoURL}
                  alt="相信美好的生活终将会到来"
                  width="112"
                  height="28"
                />
              </a>
              <a
                role="button"
                className={`navbar-burger burger ${
                  isActive ? " is-active" : " "
                }`}
                onClick={() => {
                  setIsActive(!isActive);
                }}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>
            <div
              id="navbarBasicExample"
              className={"navbar-menu" + `${isActive ? " is-active" : " "}`}
            >
              <div
                className="navbar-start"
                onClick={() => {
                  setIsActive(!isActive);
                }}
              >
                <Link className="navbar-item" to="/">
                  主页
                </Link>
                {/* <Link className="navbar-item" to="/mapu">
                  猫和老鼠
                </Link> */}
                <Link className="navbar-item" to="/findu">
                  发现
                </Link>
                {/* <Link className="navbar-item" to="/flagu">
                  立Flag
                </Link> */}
                <Link className="navbar-item" to="/beautyu">
                  锦鲤
                </Link>
                {/*  <Link className="navbar-item" to="/auctionu">
                  竞价
                  <span class="tag is-warning is-micro is-uppercase">New</span>
                </Link>
                <Link className="navbar-item" to="/needu">
                  📦盲盒
                </Link>
                <Link className="navbar-item" to="/auctionu">
                  百万宝贝
                </Link>
                <Link className="navbar-item" to="/treatu">
                  福利
                </Link> */}
                {/* <Link className="navbar-item" to="/usercenter">
                  个人中心
                </Link> */}
                <Link className="navbar-item" to="/auctionu">
                  ⛔竞价
                </Link>
                <div className="navbar-item is-hoverable  has-dropdown">
                  <a className="navbar-link">更多</a>
                  <div className="navbar-dropdown">
                    {/* <a className="navbar-item">关于</a> */}
                    <Link className="navbar-item" to="/about">
                      关于
                    </Link>
                    <a className="navbar-item">联系我们</a>
                    <hr className="navbar-divider" />
                    <Link className="navbar-item" to="/friendlylink">
                      友情链接
                    </Link>
                  </div>
                </div>
              </div>

              <div className="navbar-end">
                <div
                  className="navbar-item"
                  onClick={() => {
                    setIsActive(!isActive);
                  }}
                >
                  <AuthUserCenterShow />
                </div>
              </div>
            </div>
          </nav>

          <Switch>
            <PrivateRoute path="/mapu">
      <MapUs />
    </PrivateRoute>
            {/* <Route exact path="/account" component={Account}></Route> */}
            {/* <Route exact path="/submit" component={Submit}></Route> */}
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/findu" component={Home}></Route>
            <Route exact path="/me" component={Usercenter}></Route>
            <Route exact path="/search" component={Search}></Route>
            <Route exact path="/flagu" component={FlagUs}></Route>
            <Route exact path="/landing" component={Landing}></Route>
            {/* <Route
              exact
              path="/usercenter/:username"
              component={UserCenter}
            ></Route> */}
            <Route exact path="/register" component={Register}></Route>
            <Route exact path="/login" component={Login}></Route>
            <Route exact path="/about" component={About}></Route>
            <Route exact path="/beautyu" component={BeautyUs}></Route>
            <Route exact path="/beautyu/upload" component={Upload}></Route>

            {/* <Route exact path="/auctionu" component={AuctionUs}></Route> */}
            {/* <Route
              exact
              path="/auctionudetail"
              component={AuctionUsDetail}
            ></Route> */}
            {/* <Route
              exact
              path="/auctionudetail/:id"
              component={AuctionUsDetail}
            ></Route> */}
            <Route exact path="/auctionu" component={NoneUs}></Route>

            {/* <Route exact path="/babyu" component={BabyUs}></Route>
            <Route path="/auctionu/:tab?" exact component={AuctionUs} />
            <Route exact path="/chat" component={Chat}></Route>
            <Route exact path="/test2" component={Test2}></Route>
            <Route exact path="/ws" component={Ws}></Route> */}
            {/* <Route exact path="/userinfo" component={Usercenter}></Route> */}
            <Route exact path="*" component={Home}></Route>
            {/* <Route exact path="/MysteryBoxes" component={MysteryBoxes}></Route> */}
            {/* <Route exact path="/friendlylink" component={FriendlyLink}></Route> */}
          </Switch>
        </Router>
      </PrivateAuth>
    </div>
  );
}

function refreshToken() {
  let res = localStorage.getItem("expiration");
  let today = new Date();
  // let date =
  //   today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();
  let date = today.getMinutes();
  if (res) {
    // if (parseInt(date) > parseInt(res) + 10) {
    if (parseInt(date) !== parseInt(res)) {
      // 更新token
      fetch(ApiUtil.URL_API + "/token/refresh/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({ refresh: localStorage.getItem("refresh") }),
      }).then((res) => {
        if (res.status === 400) {
          localStorage.removeItem("access");
          localStorage.removeItem("refresh");
          localStorage.removeItem("expiration");
          localStorage.removeItem("avatar");
        } else {
          res.json().then((data) => {
            if (data.access) {
              localStorage.setItem("access", data.access);
              localStorage.setItem("refresh", data.refresh);
              localStorage.setItem("expiration", date);
              fetch(ApiUtil.URL_API + "/user-avatar/", {
                method: "GET",
                headers: {
                  "Content-type": "application/json",
                  Authorization: "JWT " + data.access,
                },
              }).then(function (res) {
                if (res.status === 200) {
                  res.json().then(function (response) {
                    localStorage.setItem("avatar", response.avatar);
                  });
                }
              });
              GetPosition();
            }
          });
        }
      });
    }
  } else {
    if (localStorage.getItem("access")) {
      localStorage.setItem("expiration", date);
    }
  }
}

// 显示是登录状态
function AuthUserCenterShow() {
  refreshToken();
  const [avatar, setAvatar] = React.useState(null);
  let auth = useAuth();
  let res = localStorage.getItem("expiration");
  let today = new Date();
  // let date =
  //   today.getFullYear() + "-" + today.getMonth() + "-" + today.getDate();
  let date = today.getMinutes();
  let access = localStorage.getItem("access");
  if (access && avatar === null) {
    // if (access && parseInt(res) + 6 > parseInt(date) && userProfile === null) {
    // 6分钟以内不过期
    var img = localStorage.getItem("avatar");
    if (img) {
      setAvatar(img);
      auth.setAccess("user");
    } else {
      fetch(ApiUtil.URL_API + "/user-avatar/", {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: "JWT " + access,
        },
      }).then(function (res) {
        if (res.status === 200) {
          res.json().then(function (response) {
            setAvatar(response.img);
            localStorage.setItem("avatar", response.avatar);
            auth.setAccess("user");
          });
        }
      });
    }
  }

  function onLogout() {
    if (Logout()) {
      setAvatar(null);
      auth.setAccess(null);
    }
  }
  return auth.user ? (
    <>
      <Link className="navbar-item" to="/me" style={{ margin: "0 auto" }}>
        <figure className="image is-25x25 ">
          <img
            className="is-rounded"
            src={ApiUtil.URL_IP + `${avatar}`}
            style={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              border: "1px solid rgba(255, 255, 0, 0.5)",
            }}
            alt=""
          />
        </figure>
      </Link>

      <Link className="navbar-item" onClick={onLogout}>
        退出
      </Link>
    </>
  ) : (
    <div className="buttons navbar-item">
      <Link
        className="button is-outlined is-timeus"
        style={{
          borderWidth: "3px",
        }}
        to="/register"
      >
        <strong>注册</strong>
      </Link>
      <Link
        className="button is-timeus"
        style={{
          borderWidth: "3px",
        }}
        to="/login"
      >
        <strong>登录</strong>
      </Link>
    </div>
  );
}

export default App;
