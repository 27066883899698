/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./Usercenter.scss";
import Card from "../../components/card/Card";
import "font-awesome/css/font-awesome.min.css";

import Settings from "../settings/Settings";
import ApiUtil from "../../apiutil/ApiUtil";
import GetCookie from "../../components/GetCookie";
import ChryLoading from "../../components/chryloading/ChryLoading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import CommentsModal from "../../components/comment/CommentsModal";

const Usercenter = () => {
  const [isFocus, setIsFocus] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [userinfo, setUserinfo] = useState(undefined);
  const [items, setItems] = useState(undefined);

  let history = useHistory();

  useEffect(() => {
    fetchMe();
    fetchUserItems();
  }, []);

  const fetchUserItems = () => {
    let csrftoken = GetCookie("csrftoken");
    let url = ApiUtil.URL_API + `/myitems/`;
    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
        ...authorization,
      },
    }).then(function (res) {
      if (res.status === 200) {
        res.json().then(function (data) {
          if (data.length > 0) {
            setItems(data);
          }
        });
      }
    });
  };

  const fetchMe = () => {
    let csrftoken = GetCookie("csrftoken");
    let url = ApiUtil.URL_API + `/me/`;
    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
        ...authorization,
      },
    }).then(function (res) {
      if (res.status === 200) {
        res.json().then(function (data) {
          setUserinfo(data);
        });
      }
    });
  };

  const handleOnShow = () => {
    setIsShow(!isShow);
  };

  const userinfoUpdate = (data) => {
    setUserinfo(data);
  };

  const showTab = () => {
    switch (isFocus) {
      case 0:
        return (
          <div className="circle-box">
            <div className="mycircle-box">
              {items &&
                items.map((item, index) => {
                  return (
                    <div style={{ margin: "0 5px 10px" }} key={index}>
                      {
                        <Card
                          itemdata={item}
                          showPic={false}
                          showReply={false}
                          index={index}
                        ></Card>
                      }
                    </div>
                  );
                })}
            </div>
          </div>
        );
      case 1:
        return (
          <div className="msg-container">
            <CommentsModal toast={toast}></CommentsModal>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="container">
      <div className="me-container">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="usercenter-container">
          {userinfo ? (
            <>
              <Settings
                isShow={isShow}
                handleOnShow={handleOnShow}
                userinfo={userinfo}
                userinfoUpdate={userinfoUpdate}
              />
              {userinfo.background_picture ? (
                <div className="cover-img">
                  <img
                    src={ApiUtil.URL_IP + userinfo.background_picture}
                    className="img-style"
                    alt=""
                  />{" "}
                </div>
              ) : (
                <div className="cover-default has-text-white">
                  <h2>TIMEUS | 真实好玩的平行世界</h2>
                </div>
              )}
              <div className="introduce-wrapper">
                <div className="introduce">
                  <div className="introduce-body">
                    <div className="introduce-left">
                      <img
                        className="user-avatar"
                        src={ApiUtil.URL_IP + `${userinfo.avatar}`}
                        alt=""
                      />
                      <div className="user-name-swapper">
                        <div className="user-name ">
                          <h1>
                            <span className="name-style">
                              {userinfo.nickname}
                            </span>
                            <span className="name-title">
                              {"  "}
                              {userinfo.tag}
                            </span>
                          </h1>
                          <div className="user-info">{userinfo.aboutme}</div>
                        </div>
                      </div>
                    </div>
                    <div className="introduce-right">
                      <button
                        className="button is-timeus is-rounded"
                        disabled
                        onClick={() => {
                          history.push("/submit/");
                        }}
                      >
                        签 到
                      </button>
                    </div>
                  </div>
                  <div className="tabs-container">
                    <a
                      onClick={() => {
                        setIsFocus(0);
                      }}
                    >
                      <span
                        className={`tab  ${isFocus === 0 ? " is-focus" : " "}`}
                      >
                        我的主页
                      </span>
                    </a>
                    <a
                      onClick={() => {
                        setIsFocus(1);
                      }}
                    >
                      <span
                        className={`tab  ${isFocus === 1 ? " is-focus" : " "}`}
                      >
                        留言
                      </span>
                    </a>
                    <a onClick={handleOnShow}>
                      <span className="tab">编辑</span>
                    </a>
                  </div>
                </div>
              </div>{" "}
              {showTab()}
              <footer class="footer">
                <div class="content has-text-centered">
                  <p>
                    <strong>Made by </strong>
                    <a href="https://www.xunsitech.com/">寻斯科技 😇</a>
                  </p>
                </div>
              </footer>
            </>
          ) : (
            <ChryLoading />
          )}
        </div>
      </div>
    </div>
  );
};

export default Usercenter;
