import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useRef, useState, useEffect } from "react";
import "./Upload.scss";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileEncode);

const Upload = () => {
  const [files, setFiles] = useState([]);
  const [item, setItem] = useState({});

  const handleOnContinue =()=>{
    let fileList = [];
    for (let i = 0; i < files.length; i++) {
      fileList.push(files[i].getFileEncodeBase64String());
    }
    console.log(files)

  }

  const handleChange = (e) => {
    setItem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="container">
      <div className="upload-container">
        <section className="hero is-timeus">
          <div className="hero-body ">
            <p className="title has-text-centered">上传资料🏆</p>
          </div>
        </section>
        <div class="content-section">
          <div className="boxed">
            <div class="field is-horizontal">
              <div class="field-label is-normal ">
                <label class="label">标题</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      className="edit-input"
                      type="text"
                      name="name"
                      id="name"
                      onChange={(e) => handleChange(e)}
                      value={item.name}
                      autocomplete="off"
                    />
                  </div>
                  <p class="help">清晰，便于识别。</p>
                </div>
              </div>
            </div>




            <div class="field is-horizontal" style={{ float: "left" }}>
              <div class="field-label is-normal ">
                <label class="label">照片</label>
              </div>
              <div>
                <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={true}
                  maxFiles={6}
                  allowFileEncode={true}
                  // server="/home"
                  name="files"
                  labelIdle="拖拽图片或者浏览"
                />
                <p class="help">首张会作为封面。</p>
                {/* <br/> */}
                <button
                    className="button is-light"
                    style={{marginTop:"1em"}}
                    onClick={() => handleOnContinue()}
                  >
                    上 传
                  </button>
              </div>
            </div>



<input type="file" 
    class="filepond"
    name="filepond"
    accept="image/png, image/jpeg, image/gif"/>

            {/* <div class="field is-horizontal"  style={{ float: "left" }}>
              
              <div class="field-body">
                <div class="field">
                  <div class="control">
                  <button
                    className="button is-light"
                    onClick={() => handleOnContinue()}
                  >
                    上 传
                  </button>
                  </div>
                </div>
              </div>
            </div> */}


           
            {/* <br/> */}
      
          </div>
        </div>
      </div>
    </div>
  );
};

export default Upload;
