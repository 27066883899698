import React from "react";
import { Link } from "react-router-dom";
import pairImage from "../../images/couple.svg";
import logoImage from "../../images/logo.png";
import "./Landing.scss";

const wordList = [
  "如果你结婚了记得给我寄张请柬，当时我没及格，我想看看标准答案。",
  "这辈子我不打扰你了，如果下辈子我们还能遇见的话，请你一定要好好爱我，她很好看。",
  "越长大越应该知道，除了公式定理，这个世界上没有标准答案，只有参考答案。",
  "夜色正好，思念正浓，而你在哪？",
  "珍惜下一秒的缘分，朋友是一辈子的，但是陌生人不会，下一秒的离开就成为永恒。",
  "今天就想了你三次，一次24小时，一次1440分钟，一次86400秒。",
  "我想有你在的未来。",
  "遇见你，爱你成了我全部。",
  "如果转角没有你，我就在转角一直等你。",
  "喜欢一个人就去表白，万一成备胎了呢？",
  "小时候词不达意，长大后言不由衷。",
  "人是会变的，简单五个字讲完了所有故事。",
  "凌晨4点，你再不来，我要下雪了。",
  "有两个你，一个在我眼里，一个在我心里。",
  "我总是会在不经意的时候看看你，你在就好。",
  "这么多年，你的身边是否还留有我的东西？",
  "珍惜每一个陌生的人，朋友一辈子都会在身边，陌生人不会，下一秒可能永远不会再见。",
  "时间不会让我忘了你，只会让我习惯没有你。",
  "人和人刚认识的时候最好。",
  "可爱是装的，可爱你不是。",
  "我走了，你一个人睡觉记得锁门。",
  "这个年纪错过就是错过了。",
  "她们说我今天很好看，要是今天能遇见你该多好。",
  "我的每支笔都记得你的名字",
  "我和你不算干净朋友，也不算正大光明的情侣，不见面就是陌生人，没有关系，我自己选的我认栽。",
  "我知道虚度年华不对，但是这样，真的好过瘾啊",
  "我不想做的你不要逼我，但是你想做的我一定会帮你完成",
  "遇到我，你是言不由衷，还是词不达意",
  "分手是每个人都会经历的，但是经历的多了，就爱不动了",
  "女人擅长背叛，男人擅长撒谎。",
  "你看我傻，我看你傻，点到为止就可以了",
  "如果你也孤独，打开它",
  "我一点都不遗憾没有在最好的时光遇到你，因为遇到你之后最好的时光才开始"
];

const Landing = () => {
  const randomWord = Math.floor(Math.random() * wordList.length);
  return (
    <div className="Landing-container">
    <div className="Landing columns">
      <div className="fold-image column is-half-desktop is-full-mobile">
        <img src={pairImage} className="image-pair" />
      </div>
      <div
        className="right column is-half-desktop"
        style={{ textAlign: "left" }}
      >
        <img src={logoImage} alt="TimeusLogo" className="logo" />

        <div
          className="card"
          style={{ borderRadius: "7px", textAlign: "left" }}
        >
          <div className="card-content">
            <p className="title">{wordList[randomWord]}</p>
            {/* <p className="subtitle">Jysatuo!</p> */}
          </div>
          <footer className="card-footer">
            <Link to="/join" className="card-footer-item">
              注册
            </Link>
            <Link to="/login" className="card-footer-item">
              登录
            </Link>
          </footer>
        </div>
      </div>
      
    </div>
    <footer class="footer">
        <div class="content has-text-centered">
          <p>
            <strong>Made by </strong>
            <a href="https://www.xunsitech.com/">寻斯科技 😇</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Landing;
