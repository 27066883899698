import "./Settings.scss";
import { useState } from "react";
import "font-awesome/css/font-awesome.min.css";
// import RichEditor from "../../components/RichEditor";
import ApiUtil from "../../apiutil/ApiUtil";
// import draftToHtml from "draftjs-to-html";
// import { EditorState, convertToRaw } from "draft-js";
import "@creativebulma/bulma-badge/dist/bulma-badge.min.css";
import GetCookie from "../../components/GetCookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Settings = ({ isShow, handleOnShow, userinfo, userinfoUpdate }) => {
  const [able, setAble] = useState(true);
  const [color, setColor] = useState("rgba(34, 34, 34, 0.05)");
  const [infotemp, setInfotemp] = useState(userinfo);

  const handleOnUpdate = (e) => {
    toast("更新中!");
    e.target.setAttribute(
      "class",
      "button is-light is-rounded is-timeus is-loading"
    );

    let csrftoken = GetCookie("csrftoken");
    let url = ApiUtil.URL_API + `/userinfo-update/`;
    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
        ...authorization,
      },
      body: JSON.stringify({ ...infotemp }),
    }).then(function (res) {
      if (res.status === 200) {
        res.json().then(function (data) {
          e.target.setAttribute(
            "class",
            "button is-light is-rounded is-timeus"
          );
          toast("更新完成!");
          userinfoUpdate(data);
          localStorage.setItem("avatar", data.avatar);
        });
      } else {
        e.target.setAttribute("class", "button is-light is-rounded is-timeus");
        toast("更新失败，请检查后重试!");
      }
    });
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var value = e.target.value;
    setInfotemp({
      ...infotemp,
      [id]: value,
    });
    setAble(false);
  };

  const onClickShow = () => {
    handleOnShow();
  };

  // const onFocusFunc = () => {
  //   if (color === "white") {
  //     setColor("rgba(34, 34, 34, 0.05)");
  //   } else {
  //     setColor("white");
  //   }
  // };

  // const onEditorStateChange = (editorState) => {
  //   const currentContent = draftToHtml(
  //     convertToRaw(editorState.getCurrentContent())
  //   );
  //   console.log(currentContent, "editorState");
  //   setInfotemp({ ...infotemp, aboutme: currentContent });
  //   console.log(infotemp);
  //   setAble(false);
  // };

  const onChangeFiles = (e) => {
    const file = e.target.files[0];
    // if (file.type !== 'image/png') {
    //     alert('请上传png图片')
    //     return}
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //成功读取文件
      setInfotemp({ ...infotemp, pic_bg: e.target.result });
    };
    setAble(false);
  };

  const onChangeAvatar = (e) => {
    const file = e.target.files[0];
    console.log("23322");
    console.log(file, 222);
    // if (file.type !== 'image/png') {
    //     alert('请上传png图片')
    //     return}
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //成功读取文件
      setInfotemp({ ...infotemp, pic_avatar: e.target.result });
    };
    setAble(false);
  };

  return (
    <div
      className="modal"
      // className="modal-box"
      style={{
        // display: "block",
        display: isShow === false ? "none" : "block",
      }}
      onClick={() => onClickShow()}
    >
      <div className="modal-background"></div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="modal settings-container"
        style={{
          display: isShow === false ? "none" : "block",
        }}
      >
        <div
          className="box"
          style={{ borderRadius: "12px" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img
            className="avatar-img"
            alt=""
            src={
              infotemp.pic_avatar
                ? infotemp.pic_avatar
                : ApiUtil.URL_IP + infotemp.avatar
            }
          />
          <div
            style={{
              width: "120px",
              height: "120px",
              top: "-60px",
              left: "calc(50% - 60px)",
              borderRadius: "50%",
              backgroundColor: "rgba(34, 34, 34, 0.55)",
              position: "absolute",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              border: "2px solid #fff",
            }}
          >
            {" "}
            <label
              className="icon is-large"
              style={{
                color: "white",
                cursor: "pointer",
              }}
            >
              <input
                className="file-input"
                onChange={onChangeAvatar}
                type="file"
                name="resume"
                accept="image/*"
              />
              <i className="fa fa-camera fa-2x" aria-hidden="true" />
            </label>
          </div>
          {/* 覆盖层 */}
          <div className="section-1  is-mobile-down">
            <div className="text-left">编辑页面</div>
            <div className="buttons">
              <button
                className="button is-light is-rounded is-hidden-mobile"
                onClick={() => onClickShow()}
              >
                取 消
              </button>
              <button
                className="button is-light is-rounded is-timeus"
                disabled={able}
                onClick={(e) => handleOnUpdate(e)}
              >
                保 存
              </button>
            </div>
          </div>
          <div className="line-bottom"></div>
          <div className="scrollbar-page">
            <div className="input-wrapper">
              <h3 className="edit-title">昵称</h3>
              <input
                className="edit-input"
                id="nickname"
                type="text"
                value={infotemp.nickname}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            <div className="input-wrapper">
              <h3 className="edit-title">标签</h3>
              <input
                className="edit-input"
                id="tag"
                type="text"
                value={infotemp.tag}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            <div className="input-wrapper">
              <h3 className="edit-title">关于我</h3>
              <input
                className="edit-input"
                id="aboutme"
                type="text"
                value={infotemp.aboutme}
                onChange={(e) => handleOnChange(e)}
              />
              {/* <div
                style={{
                  width: "600px",
                  border: "1px solid rgba(34, 34, 34, 0.05) ",
                  padding: "10px",
                  borderRadius: "2px",
                  marginTop: "8px",
                  backgroundColor: color,
                }}
              >
                {" "}
                <RichEditor
                  toolbarStyle={{
                    border: "none",
                    backgroundColor: "rgba(34, 34, 34, 0.05)",
                    margin: "-10px",
                    borderRadius: "0px",
                  }}
                  html={infotemp.aboutme}
                  onFocusFunc={onFocusFunc}
                  onEditorStateChange={onEditorStateChange}
                />
              </div> */}
            </div>
            <div className="input-wrapper">
              <h4 className="edit-title">个人主页</h4>
              <div
                class="file has-name is-light is-centered1"
                style={{ marginTop: "8px" }}
              >
                <label class="file-label">
                  <input
                    class="file-input"
                    type="file"
                    name="resume"
                    onChange={onChangeFiles}
                  />
                  <span class="file-cta">
                    <span class="file-icon">
                      <i class="fa fa-picture-o"> </i>
                    </span>
                    <span class="file-label">上传图片</span>
                  </span>
                  {infotemp.pic_bg && (
                    <span class="file-name">{infotemp.pic_bg}</span>
                  )}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
