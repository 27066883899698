import React, { useState, useEffect, useContext, createContext } from "react";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
  } from "react-router-dom";
 

const authContext = createContext();

export function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [user, setUser] = useState(null)
    const setAccess = (sth) => {
        setUser(sth)
    }

    const signin = (cb) => {            
        // return setTimeout(() => {
        //     setUser("user");
        //     cb();
        // },10)
        setTimeout(() => {
            setUser("user");
            cb();
        },100)
        return user
    }

    const signout = (cb) => {
        setUser(null);
        return setTimeout(() => {
            cb();
        },100)
    }
    
    return { user, signin, signout, setAccess}
}

export function PrivateAuth({children}) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
           {children}
        </authContext.Provider>
    )
}

// export function PrivateRoute({ children, ...rest}) {
//     var auth = useAuth();
//     // auth.setAccess('jiangyun');
//     // console.log(auth.user)
//     return (
//         <Route {...rest}
//         render={({ location }) => 
//         auth.user ? (children):
//         (<Redirect to={{
//             pathname: "/login",
//             state: { from: location }
//              }} />)}>
//         </Route>
//     )
// }

export function PrivateRoute({ children, ...rest}) {
    var auth = useAuth();
    // auth.setAccess('jiangyun');
    // console.log(auth.user)
    return (
        <Route {...rest}
        render={({ location }) => 
        auth.signin ? (children):
        (<Redirect to={{
            pathname: "/login",
            state: { from: location }
             }} />)}>
        </Route>
    )
}
 

 

export default {PrivateRoute, PrivateAuth, useAuth};
