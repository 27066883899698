import ApiUtil from "../../apiutil/ApiUtil";
import React from "react";
// import "./Home.css";
import "./Home.scss";
// import BulmaTagsInput from "@creativebulma/bulma-tagsinput";
import "font-awesome/css/font-awesome.min.css";
import "bulma/css/bulma.min.css";
import "@creativebulma/bulma-badge/dist/bulma-badge.min.css";
// import '@creativebulma/bulma-tagsinput/dist/css/bulma-tagsinput.min.css'

import Loading from "../../components/Loading";
// import Search from "./Search";
import { Link } from "react-router-dom";
import GetCookie from "../../components/GetCookie";
import { useRef, useState, useEffect } from "react";
import { DraggableArea } from "react-draggable-tags";
import { useHistory } from "react-router-dom";
// import { toast } from "bulma-toast";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import bgURL from "../../images/bg.jpg";
import ChryLoading from "../../components/chryloading/ChryLoading";

const tags = [
  { id: 1, content: "跑步" },
  { id: 2, content: "旅行" },
  { id: 3, content: "阅读" },
  { id: 4, content: "孤独" },
  { id: 5, content: "内向" },
  { id: 6, content: "自律" },
  { id: 7, content: "茶树菇" },
  { id: 8, content: "单质" },
  { id: 9, content: "淡淡的忧伤" },
  { id: 10, content: "偏执狂" },
  { id: 11, content: "理想主义" },
  { id: 12, content: "中央空调" },
  { id: 13, content: "大叔" },
  { id: 14, content: "80后" },
  { id: 15, content: "70后" },
  { id: 16, content: "90后" },
  { id: 17, content: "00后" },
  { id: 18, content: "10后" },
  { id: 19, content: "过期的小朋友" },
  { id: 20, content: "偏执狂" },
  { id: 21, content: "理性" },
  { id: 22, content: "正直" },
  { id: 23, content: "游戏" },
  { id: 24, content: "汽车" },
  { id: 25, content: "艺术" },
  { id: 26, content: "老阿姨" },
  { id: 27, content: "超爱jy" },
  { id: 28, content: "看电影" },
  { id: 29, content: "在线招男朋友" },
  { id: 30, content: "逛街" },
  { id: 31, content: "小资" },
  { id: 32, content: "K歌" },
  { id: 33, content: "坦荡" },
  { id: 34, content: "爱动物" },
  { id: 35, content: "真诚" },
  { id: 36, content: "善良" },
  { id: 37, content: "美丽" },
  { id: 38, content: "梦想" },
  { id: 39, content: "眼缘" },
  { id: 40, content: "小众" },
  { id: 41, content: "约饭" },
  { id: 42, content: "交友" },
  { id: 43, content: "道歉" },
  { id: 44, content: "树洞" },
  { id: 45, content: "看电影" },
  { id: 46, content: "心情" },
];

const Home = () => {
  const inputRef = useRef(null);
  const [activeItem, setActiveItem] = useState({
    id: null,
    content: "",
    picture: "",
    tags: [
      tags[Math.floor(Math.random() * tags.length)],
      // tags[Math.floor(Math.random() * tags.length)],
      // tags[Math.floor(Math.random() * tags.length)],
    ],
  });

  const [items, setItems] = useState(undefined);
  const [placeholder, setPlaceholder] = useState(true);

  let history = useHistory();

  useEffect(() => {
    fetchItems();
    return () => {};
  }, []);

  const onChangeFiles = (e) => {
    const file = e.target.files[0];
    // if (file.type !== 'image/png') {
    //     alert('请上传png图片')
    //     return}
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //成功读取文件
      setActiveItem({
        ...activeItem,
        picture: e.target.result,
      });
    };
  };

  // const handleOnClick = () => {
  //   console.log("handleOnClick...");
  // };

  const fetchItems = () => {
    console.log("Fetching...");
    fetch(ApiUtil.URL_API + "/items-list/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "zheloi");
        setItems(data);
      });
  };

  const onSearch = () => {
    // let { from } = location.state || { from: { pathname: "/" } };
    history.replace(`/search/?${activeItem.content}`);
    // return <Link
    //   to={{pathname: `/search/?${activeItem.title}`,
    //        state: activeItem.title,}}></Link>
  };
  // const handleSearch=()=>{
  //   console.log('222333')
  //   let csrftoken = GetCookie("csrftoken");
  //   let title = activeItem.title
  //   let url = ApiUtil.URL_API + `/search/?text=${title}`;
  //   let authorization = localStorage.getItem("access")
  //   ? { Authorization: "JWT " + localStorage.getItem("access") }
  //   : "";
  //   fetch(url, {
  //     method: "GET",
  //     headers: {
  //       "Content-type": "application/json",
  //       "X-CSRFToken": csrftoken,
  //       ...authorization,
  //     },
  //   })
  //   .then((response)=>{
  //     if (response.status === 200){
  //     response.json().then((data)=>{console.log(data);setItems(data.results)})
  //   }})
  // }

  const changeImmediate = (new_data) => {
    const newArray = Array.from(new_data).map((item, index) => item);
    setItems(newArray);
  };

  const onLikecount = (e, item_id) => {
    e.preventDefault();
    console.log(e.target.id, "===", item_id);
    items[e.target.id].likecount = items[e.target.id].likecount + 1;
    changeImmediate(items); // 深拷贝使数据实时变化

    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";
    fetch(ApiUtil.URL_API + "/item-likecount/", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: authorization,
      },
      body: JSON.stringify({ id: item_id }),
    }).then(function (res) {
      if (res.status === 200) {
        res.json().then(function (response) {
          console.log("111");
          console.log(response);
          // items[e.target.id]= items[e.target.id]
          // setItems({...items,items[e.target.id]})
          //   localStorage.setItem("userProfile", response.img);
        });
      }
    });
  };

  const onSubmitComment = (e, item_id) => {
    e.preventDefault();
    let id = e.target.id;
    console.log(items[id].comment, "comment");
    let csrftoken = GetCookie("csrftoken");
    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";
    // console.log("authorization:",authorization)
    const promise = new Promise((resolve, reject) => {
      fetch(ApiUtil.URL_COMMENTS + "/itemcomment-create/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "X-CSRFToken": csrftoken,
          ...authorization,
        },
        body: JSON.stringify({ id: item_id, content: items[id].comment }),
      }).then(function (res) {
        if (res.status === 200) {
          // console.log("21121");
          items[id].comment = "";
          setItems([...items]);
          resolve("success");
          // res.json().then(function (response) {
          // });
        } else {
          reject("error");
        }
      });
    });
    toast.promise(promise, {
      pending: "发送中",
      success: "完成 👌",
      error: "请求被拒绝 🤯",
    });
  };

  // const onCommentChange=(e)=>{
  // }

  const handleChange = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setActiveItem({
      ...activeItem,
      [name]: value,
    });
  };

  const onInputChange = (e) => {
    var id = e.target.id;
    var value = e.target.value;
    console.log(id, value, "--");
    items[id].comment = value;
    setItems([...items]);
  };

  const handleClickDelete = (tag) => {
    const tags = activeItem.tags.filter((t) => tag.id !== t.id);
    setActiveItem({ ...activeItem, tags: tags });
  };

  const handleClickAdd = () => {
    // console.log("input值", inputRef.current.value);
    // console.log(inputRef.current.value.length, "ddd");
    if (inputRef.current.value.length < 1) {
      toast({
        message: "标签字数不能为0",
        type: "is-warning",
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        opacity: 1,
      });
      return;
    } else if (inputRef.current.value.length > 10) {
      toast({
        message: "标签字数不能大于10",
        type: "is-warning",
        position: "top-center",
        closeOnClick: true,
        pauseOnHover: true,
        opacity: 1,
      });
      return;
    }
    const tags = activeItem.tags.slice();
    tags.push({ id: Math.random(), content: inputRef.current.value });
    setActiveItem({
      ...activeItem,
      tags: tags,
    });
    inputRef.current.value = "";
  };

  const onkeydown = (e) => {
    if (e.keyCode === 13) {
      handleClickAdd();
    }
  };

  const onitemkeydown = (e, item_id) => {
    if (e.keyCode === 13) {
      onSubmitComment(e, item_id);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(e, e.target);
    // e.target.setAttribute('className','button is-timeus is-loading');
    e.target.setAttribute("class", "button is-timeus is-loading");

    var csrftoken = GetCookie("csrftoken");
    var url = ApiUtil.URL_API + "/items-create/";
    // if (this.state.editing === true) {
    //   url = ApiUtil.URL_API + `/items-update/${this.state.activeItem}/`;
    //   console.log(url);
    //   this.setState({
    //     editing: false,
    //   });
    // }
    const promise = new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "X-CSRFToken": csrftoken,
          Authorization: "JWT " + localStorage.getItem("access"),
        },
        body: JSON.stringify(activeItem),
      })
        .then((response) => {
          e.target.setAttribute("class", "button is-made is-timeus");
          if (response.status === 200) {
            fetchItems();
            // setActiveItem({ id: null, title: "", completed: false });
            if (activeItem.content) {
              resolve("success");
              // toast({
              //   message: "已发送",
              //   type: "is-success",
              //   position: "top-center",
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   opacity: 1,
              // });
            } else {
              reject("内容不能为空");
              // toast({
              //   message: "内容不能为空",
              //   type: "is-warning",
              //   position: "top-center",
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   opacity: 1,
              // });
            }
            setActiveItem({
              ...activeItem,
              id: null,
              content: "",
              picture: "",
            });
          } else {
            reject("请先登录");

            // toast({
            //   message: "请先登录",
            //   type: "is-warning",
            //   position: "top-center",
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   opacity: 1,
            // });
          }
        })
        .catch((error) => {
          console.log("ERROR: ", error);
        });
    });
    toast.promise(promise, {
      pending: "发送中",
      success: "完成 👌",
      error: {
        render({ data }) {
          return `发送错误：${data}`;
        },
        icon: "🟢",
      },
      //'请求被拒绝$ 🤯'
    });
  };

  const startEdit = (item) => {
    setActiveItem(item);
  };

  const deleteItem = (item) => {
    var csrftoken = GetCookie("crftoken");
    console.log("task.id:", item.id);
    fetch(ApiUtil.URL_API + `/items-delete/${item.id}/`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
      },
    }).then((response) => {
      this.fetchItems();
    });
  };

  const strikeUnstrike = (item) => {
    console.log("task.id:", item.id);
    item.completed = !item.completed;
    var csrftoken = GetCookie("csrftoken");
    // console.log('csrftoken:',csrftoken)
    var url = ApiUtil.URL_API + `/items-update/${item.id}/`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
      },
      body: JSON.stringify({
        completed: item.completed,
        content: item.content,
      }),
    }).then(() => {
      this.fetchItems();
    });
    console.log("item: ", item.completed);
  };

  return (
    <div className="container">
    <div className="gb-container">
      {items === undefined && <ChryLoading />}
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <div className="home-container">
      <div  className="columns">
      <div className="column is-hidden-mobile">
        </div>

        <div id="task-container1" className="column is-6">
          {items !== undefined && (
            <div id="form-wrapper">
              <div id="form">
                <div style={{ flexDirection: "column", flex: 1 }}>
                  <textarea
                    className="textarea textarea-sty"
                    placeholder={
                      placeholder
                        ? "许多人会感到孤独，但又不喜欢评论它，告诉我们，发布你想要的信息，例如#寻找饭友#球友#驴友#健身伙伴..."
                        : "说点什么"
                    }
                    value={activeItem.content}
                    rows="5"
                    name="content"
                    onChange={handleChange}
                    onFocus={() => setPlaceholder(false)}
                    onBlur={() => setPlaceholder(true)}
                    style={{
                      overflowY: "hidden",
                      border: "0 #fff",
                      outline: "none",
                      boxShadow: "0 0px 0px 0 rgba(0, 0, 0, 0.0)",
                    }}
                  />
                  <div className="field has-addons mix-ele">
                    <div style={{ width: "50%", paddingRight: "20px" }}>
                      <DraggableArea
                        tags={activeItem.tags}
                        render={({ tag, index }) => (
                          <div className="tag " undraggable="true">
                            <span>{tag.content}</span>
                            <span
                              className="icon is-small is-right"
                              onClick={() => handleClickDelete(tag)}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </span>
                          </div>
                        )}
                        onChange={(tags) =>
                          setActiveItem({
                            ...activeItem,
                            tags: tags,
                          })
                        }
                      />
                      <div className="control">
                        <button
                          className="button is-white is-hidden-mobile"
                          onClick={handleClickAdd}
                          style={{ marginRight: "80px" }}
                        >
                          <label
                            className="icon is-small "
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-plus" aria-hidden="true" />
                          </label>
                        </button>
                        <input
                          className="input"
                          id="tag"
                          type="text"
                          name="tag"
                          autoComplete="off"
                          ref={inputRef}
                          onKeyDown={(e) => onkeydown(e)}
                          style={{
                            border: "0 #fff",
                            boxShadow: "0 0px 0px 0 rgba(0, 0, 0, 0.0)",
                          }}
                          placeholder="#标签"
                        />
                      </div>
                    </div>

                    <div className="bottun-space" style={{ width: "50%" }}>
                      <div
                        className="buttons is-expanded"
                        style={{
                          height: "50px",
                          justifyContent: "flex-end",
                          marginRight: "10px",
                          border: "0 #fff",
                        }}
                      >
                        <div class="field is-grouped">
                          <button className="button is-made is-timeus">
                            <span
                              className="icon is-small "
                              style={{ cursor: "pointer" }}
                            >
                              {activeItem.picture ? (
                                <span
                                  title="Badge top right"
                                  className="badge is-warning"
                                >
                                  {/* 1 */}
                                </span>
                              ) : (
                                ""
                              )}
                              <i
                                className="fa fa-picture-o"
                                aria-hidden="true"
                              />
                              <input
                                className="file-input"
                                onChange={onChangeFiles}
                                type="file"
                                name="resume"
                                accept="image/*"
                              />
                            </span>
                            {/* </label> */}
                          </button>
                          <button
                            className="button is-made is-timeus"
                            onClick={onSearch}
                          >
                            {/* <label className="button is-timeus"  onClick={onSearch}> */}
                            搜索
                            {/* </label> */}
                          </button>
                          <button
                            className="button is-made is-timeus"
                            onClick={handleSubmit}
                          >
                            {/* <label className="button is-timeus"  onClick={handleSubmit}> */}
                            发布
                            {/* </label> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {items !== undefined && JSON.stringify(items) === "[]" && (
            <div
              className="task-wrapper has-text-centered"
              style={{
                backgroundColor: "white",
                border: "1px solid rgba(95,127,255,.03)",
                marginTop: "1.1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
              }}
            >
              没有信息
            </div>
          )}
          {items && (
            <div className="col list-wrapper">
              <div className="col-1">
                {items
                  .slice(0, Math.ceil(items.length / 2))
                  .map((item, index) => {
                    return (
                      <div
                        className="notification-container task-wrapper"
                        key={item + index}
                      >
                        <div className="header">
                          <div className="date">{item.createdDate}</div>
                          <div className="date">
                            <figure class="image is-32x32">
                              <img
                                className="avatar"
                                src={ApiUtil.URL_IP + item.avatar}
                                alt=""
                              />
                            </figure>
                          </div>
                        </div>
                        {item.picture ? (
                          <div className="card-image">
                            <figure class="image is-4by3">
                              <img src={ApiUtil.URL_IP + item.picture} alt="" />{" "}
                            </figure>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="content">
                          <span
                            style={{
                              color: `${
                                item.gender === "F"
                                  ? "rgb(247, 143, 179)"
                                  : "rgb(99, 205, 218)"
                              }`,
                            }}
                          >
                            {item.tags}
                          </span>{" "}
                          {/* <span style={{ color: "red" }}>{item.tags}</span>{" "} */}
                          {item.content}
                        </div>
                        <div className="foot">
                          <div>+{item.likecount}</div>
                          <div>
                            <i
                              class="fa fa-heart"
                              aria-hidden="true"
                              style={{ color: "#F9C5D7" }}
                              id={index}
                              onClick={(e) => onLikecount(e, item.id)}
                            ></i>
                          </div>

                          <div>
                            <i
                              class="fa fa-picture-o"
                              aria-hidden="true"
                              style={{ marginRight: "5px" }}
                              id={index}
                            ></i>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div
                            className="columns is-mobile"
                            style={{
                              backgroundColor: "white",
                              margin: "4px 1px 2px 1px",
                              width: "100vw",
                            }}
                          >
                            <div
                              className="column is-four-fifths"
                              style={{ padding: "1px 1px" }}
                            >
                              <div className="field">
                                <div className="control">
                                  <input
                                    className="input is-medium"
                                    type="text"
                                    placeholder="发送信息. . ."
                                    id={index}
                                    onChange={onInputChange}
                                    value={items[index].comment}
                                    onKeyDown={(e) => onitemkeydown(e, item.id)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="column has-text-centered is-one-fifth"
                              style={{
                                padding: "1px 1px",
                                backgroundColor: "white",
                              }}
                            >
                              {/* <button className="button is-small" style={{margin:"0px 0px"}}> */}
                              <span className="icon is-small is-left">
                                <i
                                  className="fa fa-ellipsis-h"
                                  aria-hidden="true"
                                  id={index}
                                  onClick={(e) => onSubmitComment(e, item.id)}
                                ></i>
                              </span>
                              {/* </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="col-2">
                {items
                  .slice(Math.ceil(items.length / 2), items.length)
                  .map((item, index) => {
                    return (
                      <div
                        className="notification-container task-wrapper"
                        key={item + index}
                      >
                        <div className="header">
                          <div className="date">{item.createdDate}</div>
                          <div className="date">
                            <figure class="image is-32x32">
                              <img
                                className="avatar"
                                src={ApiUtil.URL_IP + item.avatar}
                                alt="avatar"
                              />
                            </figure>
                          </div>
                        </div>{" "}
                        {item.picture ? (
                          <div className="card-image">
                            <figure class="image is-4by3">
                              <img src={ApiUtil.URL_IP + item.picture} alt="" />{" "}
                            </figure>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="content">
                          <span
                            style={{
                              color: `${
                                item.gender === "F"
                                  ? "rgb(247, 143, 179)"
                                  : "rgb(99, 205, 218)"
                              }`,
                            }}
                          >
                            {item.tags}
                          </span>{" "}
                          {item.content}
                        </div>
                        <div className="foot">
                          <div>+{item.likecount}</div>
                          <div>
                            <i
                              class="fa fa-heart"
                              aria-hidden="true"
                              style={{ color: "#F9C5D7" }}
                              id={Math.ceil(items.length / 2) + index}
                              onClick={(e) => onLikecount(e, item.id)}
                            ></i>
                          </div>
                          <div>
                            <i
                              class="fa fa-picture-o"
                              aria-hidden="true"
                              style={{ marginRight: "5px" }}
                              id={Math.ceil(items.length / 2) + index}
                            ></i>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div
                            className="columns is-mobile"
                            style={{
                              backgroundColor: "white",
                              margin: "4px 1px 2px 1px",
                              width: "100vw",
                            }}
                          >
                            <div
                              className="column is-four-fifths"
                              style={{ padding: "1px 1px" }}
                            >
                              <div className="field">
                                <div className="control">
                                  <input
                                    className="input is-medium"
                                    type="text"
                                    placeholder="发送信息. . ."
                                    id={Math.ceil(items.length / 2) + index}
                                    onChange={onInputChange}
                                    value={
                                      items[Math.ceil(items.length / 2) + index]
                                        .comment
                                    }
                                    onKeyDown={(e) => onitemkeydown(e, item.id)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="column has-text-centered is-one-fifth"
                              style={{
                                padding: "1px 1px",
                                backgroundColor: "white",
                              }}
                            >
                              <span className="icon is-small is-left">
                                <i
                                  className="fa fa-ellipsis-h"
                                  aria-hidden="true"
                                  id={Math.ceil(items.length / 2) + index}
                                  onClick={(e) => onSubmitComment(e, item.id)}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
        <div className="column is-hidden-mobile">
        </div>
        </div>  
      </div>
      <footer class="footer">
        <div class="content has-text-centered">
          <p>
            <strong>Made by </strong>
            <a href="https://www.xunsitech.com/">寻斯科技 😇</a>
              <strong>{" | "}</strong>
              <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">沪ICP备2021020260号-2</a>
          </p>
        </div>
      </footer>
    </div>
    </div>
  );
};

export default Home;
   