import "./About.css";
import "bulma/css/bulma.min.css";
import ApiUtil from "../../apiutil/ApiUtil";

const About = () => {
  return (
    <div className="container">
    <div className="about-container">
      <section className="articles">
        <div className="column is-8 is-offset-2">
          <div class="card article">
            <div class="card-content">
              <div class="media">
                <div class="media-center">
                  <img
                    // src="http://www.radfaces.com/images/avatars/daria-morgendorffer.jpg"
                    src={ApiUtil.URL_IP + `/media/images/None/xunsi.png`}
                    className="author-image"
                    alt=""
                  />
                </div>
                <div class="media-content has-text-centered">
                  {/* <p class="title article-title">Sapien eget mi proin sed 🔱</p>
                  <p class="subtitle is-6 article-subtitle">
                    <a href="#">@daria</a> on February 17, 2018
                  </p> */}
                </div>
              </div>
              <div class="content article-body">
                <h3 class="has-text-centered">
                  这是一款线上为辅，线下为主的社交平台。
                </h3>
                <p>
                  TIMEUS是寻斯科技旗下一款专注于线上匹配到线下的社交平台，让面对面的交流更加真实。
                </p>
                <p>
                  随着互联网的发展，更多人便捷于在屏幕前和陌生人社交，但是人与人之间的牢固关系很难单独存在于互联网，再加上线上的交流会让人丧失基本的交流能力，使人更孤独。
                </p>

                <p>
                  而TIMEUS将利用互联网的便利，让用户可以随时随地在TIMEUS上找到愿意线下交流的朋友，扩大大家线下的互动比例，最终回到线下生活，同时未来会在平台中加入更好玩的互动游戏，做一个最真实好玩的社交平台。
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="content has-text-centered">
          <p>
            <strong>Made by </strong>
            <a href="https://www.xunsitech.com/">寻斯科技 😇</a>
          </p>
        </div>
      </footer>
    </div>
        </div>

  );
};

export default About;
