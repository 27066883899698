import { useState, useEffect } from "react";
import ApiUtil from "../../apiutil/ApiUtil";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import GetCookie from "../../components/GetCookie";
import Loading from "../../components/Loading";
import "./Search.scss";
import ChryLoading from "../../components/chryloading/ChryLoading";

function Search(p) {
  const [items, setItems] = useState(undefined);
  const [msg, setMsg] = useState(undefined);
  let location = useLocation();

  useEffect(() => {
    let pk =
      p.match.params.pk ||
      location.state ||
      p.location.search.replace("?", "") ||
      {};
    let csrftoken = GetCookie("csrftoken");
    let url1 = ApiUtil.URL_API + `/search/?text=${pk}`;
    let authorization = localStorage.getItem("access")
      ? { Authorization: "JWT " + localStorage.getItem("access") }
      : "";
    fetch(url1, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        "X-CSRFToken": csrftoken,
        ...authorization,
      },
    }).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          if (data.results.length !== 0) {
            setItems(data.results);
          } else {
            setMsg(
              <div>
                暂无相关信息,返回
                <Link
                  to="/"
                  style={{
                    fontSize: "1rem",
                    border: "none",
                    color: "#003300",
                  }}
                >
                  <strong>首页</strong>
                </Link>
              </div>
            );
            console.log("如果没有数据返回，则显示在也页面让用户上传团购");
          }
        });
      }
    });
  }, [p.match.params.pk]);

  return (
    <div className="task-container">
      {/* <div className="box1" style={{ margin: "10px 20px", textAlign: "center" }}>
      <h5 className="title is-5" style={{ fontWeight: "lighter" }}>
        搜索结果
      </h5> */}
      <div className="result-container">
      {items ? (
        <div className="col list-wrapper">
          <div className="col-1">
            {items.slice(0, items.length / 2).map((item, index) => {
              return (
                <div
                  className="notification-container task-wrapper"
                  key={index}
                >
                  <div className="header">
                    <div className="date">{item.createdDate}</div>
                    <div className="date">
                      <figure class="image is-32x32">
                        <img
                          className="avatar"
                          src={ApiUtil.URL_IP + item.avatar}
                          alt="avatar"
                        />
                      </figure>
                    </div>
                  </div>
                  {item.picture ? (
                    <div className="card-image">
                      <figure class="image is-4by3">
                        <img src={ApiUtil.URL_IP + item.picture} alt="" />{" "}
                      </figure>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="content">
                    <span
                      style={{
                        color: `${
                          item.gender === "F"
                            ? "rgb(247, 143, 179)"
                            : "rgb(99, 205, 218)"
                        }`,
                      }}
                    >
                      {item.tags}
                    </span>{" "}
                    {item.content}
                  </div>
                  <div className="foot">
                    <i
                      class="fa fa-heart"
                      aria-hidden="true"
                      style={{ color: "#F9C5D7" }}
                    ></i>
                  </div>
                  <div className="card-footer">
                    <div
                      className="columns is-mobile"
                      style={{
                        backgroundColor: "white",
                        margin: "4px 1px 2px 1px",
                        width: "100vw",
                      }}
                    >
                      <div
                        className="column is-four-fifths"
                        style={{ padding: "1px 1px" }}
                      >
                        <div className="field">
                          <div className="control">
                            <input
                              className="input is-medium"
                              type="text"
                              placeholder="发送信息. . ."
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="column has-text-centered is-one-fifth"
                        style={{
                          padding: "1px 1px",
                          backgroundColor: "white",
                        }}
                      >
                        {/* <button className="button is-small" style={{margin:"0px 0px"}}> */}
                        <span className="icon is-small is-left">
                          <i
                            className="fa fa-ellipsis-h"
                            aria-hidden="true"
                          ></i>
                        </span>
                        {/* </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="col-2">
            {items.slice(items.length / 2, items.length).map((item, index) => {
              // {index/2==1?<div className="col-1">:<div className="col-2">}
              return (
                <div className="notification-container task-wrapper">
                  <div className="header">
                    <div className="date">{item.createdDate}</div>
                    <div className="date">
                      <figure class="image is-32x32">
                        <img
                          className="avatar"
                          src={ApiUtil.URL_IP + item.avatar}
                          alt=""
                        />
                      </figure>
                    </div>
                  </div>
                  {item.picture ? (
                    <div className="card-image">
                      <figure class="image is-4by3">
                        <img src={ApiUtil.URL_IP + item.picture} alt="" />{" "}
                      </figure>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="content">
                    <span
                      style={{
                        color: `${
                          item.gender === "F"
                            ? "rgb(247, 143, 179)"
                            : "rgb(99, 205, 218)"
                        }`,
                      }}
                    >
                      {item.tags}
                    </span>{" "}
                    {item.content}
                  </div>
                  <div className="foot">
                    <i
                      class="fa fa-heart"
                      aria-hidden="true"
                      style={{ color: "#F9C5D7" }}
                    ></i>
                  </div>
                  <div className="card-footer">
                    <div
                      className="columns is-mobile"
                      style={{
                        backgroundColor: "white",
                        margin: "4px 1px 2px 1px",
                        width: "100vw",
                      }}
                    >
                      <div
                        className="column is-four-fifths"
                        style={{ padding: "1px 1px" }}
                      >
                        <div className="field">
                          <div className="control">
                            <input
                              className="input is-medium"
                              type="text"
                              placeholder="发送信息. . ."
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="column has-text-centered is-one-fifth"
                        style={{
                          padding: "1px 1px",
                          backgroundColor: "white",
                        }}
                      >
                        {/* <button className="button is-small" style={{margin:"0px 0px"}}> */}
                        <span className="icon is-small is-left">
                          <i
                            className="fa fa-ellipsis-h"
                            aria-hidden="true"
                          ></i>
                        </span>
                        {/* </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          
        </div>
      ) : (
        <>{msg ? <>{msg}</> : <ChryLoading />}</>
      )}
      {/* <footer className="footer">
        <div className="content has-text-centered">
          <p>
            <strong>Made by </strong>
            <a href="https://www.xunsitech.com/">寻斯科技 😇</a>
          </p>
        </div>
      </footer> */}
    </div>
    </div>

  );
}

export default Search;
