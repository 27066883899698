import React from 'react';
import Geolocation from 'react-amap-plugin-geolocation';
import { Map, MouseTool, Markers, Marker } from 'react-amap';
import ApiUtil from "../../apiutil/ApiUtil";
import MyModal from "../../components/MyModal";
import "./MapUs.scss";

const plugins = [
    'OverView',
    'ControlBar',
    // 'ToolBar',
];

// const pos = [{postion: { longitude: 120, latitude: 35 }, nickname:"jiaungyn",url: "https://www.tutorialspoint.com/bootstrap/images/64.jpg"}, {position:{ longitude: 120, latitude: 40 },nickname:"jiaungyndsd",url: "https://www.tutorialspoint.com/bootstrap/images/64.jpg"}, {position:{ longitude: 121, latitude: 20 }, nickname:"jiaunsssgyn",url: "https://www.tutorialspoint.com/bootstrap/images/64.jpg"}]
const pos = [
    {position: { longitude: 121.41941, latitude: 31.21563 }, nickname:"jiaungyn4",url: "https://media.tiantiantuanche.com/media%2Fimages%2F2021%2F12%2Fcheese-g585262442_640.png"}, 
    {position: { longitude: 121.41981, latitude: 31.21763 }, nickname:"jiaungyn2",url: "https://media.tiantiantuanche.com/media/images/2021/12/black-cat-g8db9e4dca_640.png"}, 
    {position: { longitude: 121.41981, latitude: 31.21463 }, nickname:"jiaungyn0",url: "https://media.tiantiantuanche.com/media/images/2021/12/gray-tabby-cat-ged4d79a13_640.png"}, 
    {position: { longitude: 121.42181, latitude: 31.21763 }, nickname:"jiaungyn3",url: "https://media.tiantiantuanche.com/media/images/2021/12/calico-cat-gaef1d07c7_640.png"}, 
    {position: { longitude: 121.41981, latitude: 31.21833 }, nickname:"jiaungyn1",url: "https://media.tiantiantuanche.com/media/images/2021/12/mouse-g632bc96a7_640.png"}, 
    {position: { longitude: 121.41881, latitude: 31.21833 }, nickname:"jiaungyn",url: "https://media.tiantiantuanche.com/media/images/2021/12/white-cat-g327196222_640.png"}, {position:{ longitude: 121.41781, latitude: 31.21633 },nickname:"jiaungyndsd",url: "https://media.tiantiantuanche.com/media/images/2021/12/orange-tabby-cat-g479f649da_640.png"}, {position:{ longitude: 121.41981, latitude: 31.21635 }, nickname:"jiaunsssgyn",url: "https://media.tiantiantuanche.com/media/images/2021/12/orange-tabby-cat-g221d6aee7_640.png"}]

// const pos = [{ longitude: 120, latitude: 35 }, { longitude: 120, latitude: 40 }, { longitude: 121, latitude: 20 }]

class MapUs extends React.Component {
    constructor() {
        super();
        const self = this;
        this.state = {
            what: "",
            markers: [],
            textMarkers: { longitude: 120, latitude: 25 },
            isShow: false,
            pickPosition: ""
        }

        this.toolEvents = {
            created: (tool) => {
                self.tool = tool
            },
            draw({ obj }) {
                self.markerPosition(obj);
            }
        }

        // 获取坐标的信息,以后用来获取头像
        this.markersEvents = {
            click(e, marker) {
                const extData = marker.getExtData();
                console.log(extData.position)
                // console.log(self.state)
                // console.log(this)

                self.setState({ isShow: !self.state.isShow, pickPosition: extData.position })
                // console.log(self.state)
                // alert(`"点击"${extData.position.latitude}`)
                // const extData = marker.getExtData();
                // const index = extData.myIndex;
            }
        }

        // this.icon = new window.AMap.Icon({
        //     image: "./example.jpg",//自定义icon
        //     size: new window.AMap.Size(42, 42)//icon的大小
        //   });


        this.amapEvents = {
            created: mapInstance => {
                console.log('高德地图 Map 实例创建成功；如果你要亲自对实例进行操作，可以从这里开始。比如：');
                console.log('缩放级别：', mapInstance.getZoom());
                // mapInstance.setMapStyle('amap://styles/whitesmoke') 
                mapInstance.setMapStyle('amap://styles/73551cc30ab67a23da69af462d1c525a')
                this.mapInstance = mapInstance

                window.AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.CitySearch', 'AMap.Geolocation'], () => {
                    // 实例化Autocomplete
                    const autoOptions = {
                        // city 限定城市，默认全国
                        // city: '025',
                        // input 为绑定输入提示功能的input的DOM ID
                        input: 'amapInput',
                    }
                    const autoComplete = new window.AMap.Autocomplete(autoOptions);
                    // 无需再手动执行search方法，autoComplete会根据传入input对应的DOM动态触发search

                    const placeSearch = new window.AMap.PlaceSearch({
                        // city: '南京',
                        map: mapInstance,
                    })


                    const geolocation = new window.AMap.Geolocation({
                        enableHighAccuracy: true,//是否使用高精度定位，默认:true
                        timeout: 10000,          //超过10秒后停止定位，默认：无穷大
                        maximumAge: 0,           //定位结果缓存0毫秒，默认：0
                        convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
                        showButton: true,        //显示定位按钮，默认：true
                        buttonPosition: 'LB',    //定位按钮停靠位置，默认：'LB'，左下角
                        buttonOffset: new window.AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                        showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
                        showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
                        panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
                        zoomToAccuracy: true      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    });
                    const mapObj = new window.AMap.Map('iCenter');
                    mapObj.addControl(geolocation);
                    geolocation.getCurrentPosition()

                    window.AMap.event.addListener(geolocation, 'complete', self.onComplete);//返回定位信息
                    // window.AMap.event.addListener(geolocation, 'error', onError);      //返回定位出错信息

                    const citySearch = new window.AMap.CitySearch()
                    citySearch.getLocalCity((status, result) => {
                        if (status === 'complete' && result.info === 'OK') {
                            // 查询成功，result即为当前所在城市信息
                            console.log('当前所在城市：', result)
                            if (result && result.city && result.bounds) {
                                // 当前城市名称
                                // const cityinfo = result.city;
                                // 当前城市位置信息
                                const citybounds = result.bounds;
                                // 地图显示当前城市
                                mapInstance.setBounds(citybounds);
                                // 需要在设置坐标成功后，重新设置 缩放级别
                                mapInstance.setZoom(15)
                            }
                        }
                    })
                })

                //   // 实例点击事件
                //   mapInstance.on('click', e => {
                //     const lngLat = `${e.lnglat.getLat()},${e.lnglat.getLng()}`
                //     console.log('坐标位置:', lngLat)
                //     // this.props.onChange(lngLat)
                //   });
            },
        };
        //   this.markerEvents = {
        //     created: markerInstance => {
        //       console.log('高德地图 Marker 实例创建成功；如果你要亲自对实例进行操作，可以从这里开始。比如：');
        //       console.log(markerInstance.getPosition());

        //       this.markerInstance = markerInstance
        //     },
        //   }
        //   this.markerPosition = { longitude: 121.38468, latitude: 31.109 };
    }




    onComplete(obj) {
        console.log(obj.message);
        console.log('hhahahah')
        console.log(obj)
    }

    onError(obj) {
        console.log(obj.message);
    }

    // handleDcl=()=>{
    //    {
    //     (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    //           var $notification = $delete.parentNode;

    //           $delete.addEventListener('click', () => {
    //             $notification.parentNode.removeChild($notification);
    //           });
    //         });
    //       }; 

    // }

    // componentWillMount(){
    //     window.document.addEventListener('DOMContentLoaded', () => {
    //         (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    //             var $notification = $delete.parentNode;

    //             $delete.addEventListener('click', () => {
    //                 $notification.parentNode.removeChild($notification);
    //             });
    //         });
    //     });
    //     // window.removeEventListener('DOMContentLoaded' ,this.handleDcl, true)
    // }
    // componentDidUpdate=()=>{
    //     window.document.addEventListener('DOMContentLoaded', () => {
    //         (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    //             var $notification = $delete.parentNode;

    //             $delete.addEventListener('click', () => {
    //                 $notification.parentNode.removeChild($notification);
    //             });
    //         });
    //     },{ once: true });
    // }

    componentDidMount = () => {
        console.log('来这里过')
        this.timer = setTimeout(
            () => {
                // alert("Hello");
                console.log("woshidingshiqidadaad")
                // this.setState({content:"我是定时器哦！！！"})
            }, 5000
        );
        // window.document.addEventListener('DOMContentLoaded',this.handleDcl,false)
        window.document.addEventListener('DOMContentLoaded', () => {
            (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
                var $notification = $delete.parentNode;

                $delete.addEventListener('click', () => {
                    $notification.parentNode.removeChild($notification);
                });
            });
        }, { once: true });


    }

    // componentWillUnmount = () => {
    //     this.timer && clearTimeout(this.timer);
    // }

    // componentDidMount() {
    //     this.fetchMarker()
    // }


    fetchMarker = () => {
        console.log("FetchingMarker...")
        fetch(ApiUtil.URL_API + '/marker-cluster/')
            .then(respose => respose.json())
            .then(data => this.setState({
                markers: data
            }
            ))
    }



    markerPosition(obj) {
        console.log(obj.getPosition())
        let text = `你绘制了一个标记，坐标位置是 {${obj.getPosition()}}`;
        this.setState({
            what: text
        });
    }

    choiceMarker = (obj) => {
        if (this.tool) {
            this.tool.marker()
        }
        this.setState({
            what: '准备画图'
        });
    }

    // choiceMarker=(obj)=>{

    //   this.setState({textMarkers:{longitude:120,latitude:40}})
    //   console.log(this.state)
    // }
    onClickShow = () => {
        this.setState({ isShow: !this.state.isShow })
    }

    // 改变marker的图标
    renderMarkerIcon = (extData) => {
        <div style={{ position: 'relative' }}>
            <div style={{ width: 16, height: 16 }}>
                <i style={{ 'color': '#FF7A00', 'margin': '0 4px' }} src={'//a.amap.com/jsapi_demos/static/demo-center/icons/dir-via-marker.png'} />
            </div>
        </div>

    }

    renderLayout(extData){
        return (
            <div style={{position: "relative"}}>
                <figure class = "image is-32x32">
                     <img className="is-rounded" src = {extData.url} />
                    {/* <img src = "https://www.tutorialspoint.com/bootstrap/images/64.jpg" /> */}
                 </figure>
            </div>
        )
    }



    render() {
        return (
            <div className="container">
                {/* <div  className="box" > */}
                {/* <article>
  <div class="notification is-primary" style={{display:"flex"}}>
    This container is <strong>centered</strong> on desktop and larger viewports.
  </div>
  </article> */}
                {/* </div> */}



                {/* <button class="button is-primary"
              onClick={this.onClickShow}>Click to see modal</button> */}
                <MyModal isShow={this.state.isShow} pickPosition={this.state.pickPosition} handleOnClick={this.onClickShow} />

                <div style={{ margin: "0px auto", height: "80vh", maxWidth: "90vw", border: "10px solid #f0f0f0", borderRadius: "10px", backgroundColor: "#f0f0f0" }}>
                    <Map showButton={false} version={"1.4.15"} plugins={plugins} events={this.amapEvents} amapkey={'d985564ffc04b7ecdce9216965940cc9'} zoom={15} ><Geolocation {...{
                        enableHighAccuracy: true,
                        timeout: 20000,
                        showButton: true,
                    }} />



                        <MouseTool events={this.toolEvents} />
                        {/* <Marker position={{longitude: 120, latitude:31.13021, icon: this.icon}} /> */}
                        <Markers useCluster={true}
                            events={this.markersEvents}
                            render={this.renderLayout}
                            markers={pos}/>
                            {/* // markers={pos.map((e, idx) => {
                            //     return ({ position: { longitude: e.longitude, latitude: e.latitude }, icon: this.icon, title: "zheli" })
                            // })} /> */}
                        <div style={layerStyle}>{this.state.what}</div>
                    </Map>
                    {/* <button onClick={() => { this.choiceMarker() }}>choiceMarker</button> */}
                    {/*  markers={pos.map((e, idx)=>{
                    return({position:{longitude:e.longitude,latitude:e.latitude}})})} */}
                </div>

            </div>
        )
    }
}

const layerStyle = {
    border: "none",
    color: "#686868",
    background: "#f0f0f0",
    position: 'absolute',
    top: "10px",
    left: "10px"
}




export default MapUs;

