export default class ApiUtil {
  static URL_IP = "https://timeus.top/v2";
  // static URL_IP = "http://127.0.0.1:8000";
  // static URL_IP = "http://jysatuo.gicp.net:19246";
  static URL_API = ApiUtil.URL_IP + "/api";
  static URL_AUCTIONUS = ApiUtil.URL_IP + "/auctionus";
  static URL_COMMENTS = ApiUtil.URL_IP + "/comments";
  static URL_CHAT = ApiUtil.URL_IP + "/chat";
  static URL_NOTIFICATION = ApiUtil.URL_IP + "/notification";
  static URL_MEDIA = ApiUtil.URL_IP + "/media";
}
