import "./BeautyCard.scss";
import Zmage from 'react-zmage';

const BeautyCard = ({item}) => {
  return (
    <div className="beauty-card">
      <div className="card-title">
        <a href="/amas" className="name">
          /amas
        </a>
        <label className="like">Upcoming</label>
      </div>
      <div className="card-image">
        <figure class="image is-4by3">
          <Zmage
            alt=""
            style={{ width: "100%" }}
            src="https://source.unsplash.com/random/1280x960"
            set={[{
              src: "https://source.unsplash.com/random/1280x960",
              alt: ""
          },{
              src: "https://source.unsplash.com/random/1280x960",
              alt: ""
          }]}
            // src="https://bulma.io/images/placeholders/256x256.png"
          />
                                              {/* <Zmage src="https://source.unsplash.com/random/1280x960" /> */}

          {/* <a onClick={() => Zmage.browsing({ src:"https://source.unsplash.com/random/1280x960"})}>任意元素</a> */}
        </figure>
      </div>
    </div>
  );
};

export default BeautyCard;
